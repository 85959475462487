import { Component, OnInit } from '@angular/core';
import { ViewManagerService } from '../../../../services';
import { RequestInformationService } from '../../../../services/summaryRequest/request-information.service';
import { View } from '../../../../enums';

@Component({
  selector: 'app-data-verification',
  templateUrl: './data-verification.component.html',
  styleUrls: ['./data-verification.component.scss']
})
export class DataVerificationComponent implements OnInit {

  public users: any;
  public usersDoc: any;
  constructor(
    private viewManager: ViewManagerService,
    private request: RequestInformationService
  ) { }

  ngOnInit(): void {
    this.request.summaryRequest().subscribe((data) => {
      this.users = data;
      this.users.forEach(user => {
        this.modelData.Producto_de_interes = user.productName,
        this.modelData.Company = user.company.name,
        this.modelData.Numero_Documento_Empresa = user.company.documentNumber,
        this.modelData.solicitante.name = user.requestor.givenName,
        this.modelData.solicitante.Tipo_De_Documento = user.requestor.documentType,
        this.modelData.solicitante.Numero_Documento_Persona = user.requestor.documentNumber,
        this.modelData.solicitante.Email = user.requestor.email,
        this.modelData.solicitante.Mobile = user.requestor.mobileNumber,
        this.modelData.solicitante.solicitante = user.requestor.isTheLegalRepresentative,
        this.modelData.representante_legal.name = user.legalRepresentative.givenName,
        this.modelData.representante_legal.Tipo_Documento_RL = user.legalRepresentative.documentType,
        this.modelData.representante_legal.N_mero_de_documento_representante_legal = user.legalRepresentative.documentNumber,
        this.modelData.representante_legal.Correo_electr_nico_RL = user.legalRepresentative.email,
        this.modelData.representante_legal.Tel_fono_RL = user.legalRepresentative.mobileNumber,
        this.modelData.company_documents = user.documents
      });
    });
  }

  cancel() {
    console.log('cancel request');
  }

  next() {
    this.viewManager.setView({ view: View.REGULATIONS });
  }


  modelData = {
    id: '',
    Producto_de_interes: '',
    Company: '', 
    Numero_Documento_Empresa: '', 
    solicitante: {
      name: '', 
      Tipo_De_Documento: '', 
      Numero_Documento_Persona: '',
      Email: '',
      Mobile: '',
      solicitante: '', 
    },
    representante_legal: {
      name: '', 
      Tipo_Documento_RL: '', 
      N_mero_de_documento_representante_legal: '',
      Correo_electr_nico_RL: '',
      Tel_fono_RL: ''
    },
    company_documents: 
      [{
        name: '',
        url: ''
      },
      {
        name: '',
        url: ''
      },
      {
        name: '',
        url: ''
      },
      ]
  };
}
