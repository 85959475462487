import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewModel, NotificationModel } from '../../interfaces';
import { ViewManagerService } from '../../services';
import { View } from '../../enums';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  public selectedView: ViewModel;
  public viewTypes = View;
  public amountOfSteps = 4;

  public applicationCompletedCopy: NotificationModel = {
    title: 'Gracias por completar la información requerida, procederemos a verificar su información. Recibirá una respuesta vía email próximamente.',
    subtitle: 'Ya puede cerrar la ventana',
    buttonText: 'Volver al inicio',
  };

  public verificationCompletedCopy: NotificationModel = {
    title: 'Bienvenido',
    subtitle: 'Le hemos enviado un correo electrónico para acceder a su nueva cuenta, por favor revise su casilla.',
    buttonText: 'Volver al inicio',
  };

  constructor (
    private viewManager: ViewManagerService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.viewManager.view$.subscribe((selectedView) => {
      this.selectedView = selectedView;
      window.scroll(0, 0);
    });

    this.applicationCompletedCopy.buttonFunc = () => {
      this.router.navigate(['/landing']);
    };

    this.verificationCompletedCopy.buttonFunc = () => {
      this.router.navigate(['/landing']);
    };

    this.route.params.subscribe((params) => {
      const { type } = params;

      if (type === 'representante-legal') {
        this.viewManager.setView({ view: View.LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION });
        this.amountOfSteps = 4;
        // LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION
        // LEGAL_REPRESENTATIVE_TERMS_AND_CONDITIONS
        // DATA_VERIFICATION
        // REGULATIONS
        // VERIFICATION_COMPLETED
      }
    });
  }

}
