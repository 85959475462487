
<div class="row justify-content-center no-gutters">
  <div class="col-12 col-md-8">
    <h3>
      Producto solicitado:
    </h3>

    <ul>
      <li>
        <div class="data">
          {{modelData.Producto_de_interes}}
        </div>
      </li>
    </ul>

    <h3>
      Empresa
    </h3>

    <ul>
      <li>
        <div class="title">
          Razón Social
        </div>
        <div class="data">
          {{modelData.Company}}
        </div>
      </li>
      <li>
        <div class="title">
          NIT
        </div>
        <div class="data">
          {{modelData.Numero_Documento_Empresa}}
        </div>
      </li>
    </ul>

    <h3>
      Solicitante <span *ngIf="modelData.solicitante.solicitante">- Representante Legal</span>
    </h3>

    <ul>
      <li>
        <div class="title">
          Nombre
        </div>
        <div class="data">
          {{modelData.solicitante.name}}
        </div>
      </li>
      <li>
        <div class="title">
          Tipo de documento
        </div>
        <div class="data">
          {{modelData.solicitante.Tipo_De_Documento}}
        </div>
      </li>
      <li>
        <div class="title">
          Nro de Documento
        </div>
        <div class="data">
          {{modelData.solicitante.Numero_Documento_Persona}}
        </div>
      </li>
      <li>
        <div class="title">
          Email
        </div>
        <div class="data">
          {{modelData.solicitante.Email}}
        </div>
      </li>
      <li>
        <div class="title">
          Celular
        </div>
        <div class="data">
          {{modelData.solicitante.Mobile}}
        </div>
      </li>
    </ul>

    <div *ngIf="!modelData.solicitante.solicitante">
      <h3>
        Representante Legal
      </h3>

      <ul>
        <li>
          <div class="title">
            Nombre
          </div>
          <div class="data">
            {{modelData.representante_legal.name}}
          </div>
        </li>
        <li>
          <div class="title">
            Tipo de documento
          </div>
          <div class="data">
            {{modelData.representante_legal.Tipo_Documento_RL}}
          </div>
        </li>
        <li>
          <div class="title">
            Nro de Documento
          </div>
          <div class="data">
            {{modelData.representante_legal.N_mero_de_documento_representante_legal}}
          </div>
        </li>
        <li>
          <div class="title">
            Email
          </div>
          <div class="data">
            {{modelData.representante_legal.Correo_electr_nico_RL}}
          </div>
        </li>
        <li>
          <div class="title">
            Celular
          </div>
          <div class="data">
            {{modelData.representante_legal.Tel_fono_RL}}
          </div>
        </li>
      </ul>
    </div>

    <h3>
      Documentación de la empresa
    </h3>

    <ul>
      <li *ngFor="let item of modelData.company_documents">
        <div class="title">
          {{item.name}}
        </div>
        <div class="data link">
          <a href="{{item.url}}" title="{{item.name}}" target="_blank">
            <span class="material-icons">
              attach_file
            </span>
            {{item.name}}
          </a>
        </div>
      </li>
    </ul>
    <div class="row no-gutters">
      <div class="col-12 col-md-6 order-12 order-md-1">
        <button class="btn-primary-outline" (click)="cancel()" type="button">
          <span class="material-icons">
            close
          </span>
          Cancelar
        </button>
      </div>
      <div class="col-12 col-md-6 order-1 order-md-12 d-flex justify-content-end" >
        <button class="btn-primary" (click)="next()">
          Siguiente
          <span class="material-icons">
            arrow_forward_ios
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

