<div *desktopOnly class="landing-desktop">
  <div class="container">
    <div class="row justify-content-center">
      <div class="bg-blue">
        <div class="fondo"></div>
        <div class="logo">
          <img class="logo" src="../../../assets/images/logo2.png">
        </div>
        <div>
          <h3 class="textTwo">Obtén ya</h3>
          <h4 class="textThree">Tu cuenta de ahorros Pyme</h4>
          <!-- <h6 class="textFour">Menos burocrasia mas control<br>para su negocio</h6> -->
          <h6 class="textFour">
            Menos burocracia, más <span class="outline">CONTROL</span>
            <br />
            para tu negocio
          </h6>
        </div>
      </div>

      <div class="col col-md-8 col-12 m-auto">

        <div class="row">
          <!-- First Column -->
          <div class="col-12 col-lg-6">
            <div class="color-box">
              <div class="box">
                <div>
                  <h3 class="titlePrimary">
                    <img class="logo-u" src="../../../assets/images/icons/ic-personas.svg">
                    Unipersonal
                  </h3>
                  <div class="description">
                    Si eres persona natural y cuentas con tu negocio o trabajar de manera independiente también podrás disfrutar de nuestros beneficios, solo debes tener la siguiente información:
                  </div>
                  <div>
                    <h3 class="titleSecondary">
                      Soy persona con establecimiento
                    </h3>
                  </div>
                  <div class="item" id="secondItem">
                    <ul>
                      <li>Identificación Personal</li>
                      <li>Cámara de comercio</li>
                      <li>Rut</li>
                    </ul>
                  </div>
                  <div>
                    <h3 class="titleSecondary">
                      Soy Independiente
                    </h3>
                  </div>
                  <div class="item" id="secondItem">
                    <ul>
                      <li>Identificación Personal</li>
                      <li>Rut</li>
                    </ul>
                    <p class="text-muted">
                      Por tu seguridad, verificaremos tu identidad y la veracidad de la información, si cumples con todos los requisitos
                      podrás disfrutar de los beneficios de tu cuenta de ahorros digital Pymes.
                    </p>
                  </div>

                </div>
              </div>

              <div class="btnBox" id="btnboxSecond">
                <button class="btnBoxItem" id="btnboxSecond" routerLink="/onboarding/unipersonal">
                  Comenzar solicitud
                </button>
              </div>
            </div>

          </div>

          <!-- Second Column -->
          <div class="col-12 col-lg-6">
            <div class="color-box" id="boxSecond">
              <div class="box" id="boxSecond">
                <div>
                  <h3 class="titlePrimary">
                    <img class="logo-e" src="../../../assets/images/icons/ic-empresa.svg">
                    Empresa
                  </h3>
                  <div class="description">
                    Prepárate para solicitar tu cuenta de ahorro digital Pymes en línea.
                  </div>
                  <div>
                    <h3 class="titleSecondary">
                      Si eres el administrador de la cuenta, solo debes tener la siguiente información:
                    </h3>
                  </div>

                  <div class="item" id="secondItem">
                    <ul>
                      <li>Identificación personal y de la empresa</li>
                      <li>Cámara de comercio o Certificado de existencia y presentación Legal</li>
                      <li>Composición Accionaria ( Solo si no esta explicita en la cámara de comercio)</li>
                      <li>Información del Representante legal de la compañía</li>
                    </ul>
                    <p class="text-muted">
                      Por tu seguridad, verificaremos tu identidad y la veracidad de la información, si cumples con todos los requisitos
                      podrás disfrutar de los beneficios de tu cuenta de ahorros digital Pymes.
                    </p>
                  </div>

                </div>
              </div>
              <div class="btnBox" id="btnboxSecond">
                <!-- <button class="btn-secondary" (click)="navigate('unipersonal')"> -->
                <button class="btnBoxItem" id="btnboxSecond" routerLink="/onboarding/empresa">
                  Comenzar solicitud
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- -------------------------------------------------------------------version mobile----------------------------------- -->

<div *mobileOnly class="landing-mobile">
    <div class="header-mobile">
      <div class="logo-mobile">
        <img class="logo-mobile" src="../../../assets/images/logo2.png">
      </div>
      <div class="fondo-mobile"></div>
      <div>
        <h3 class="textTwo">Obtenga ya</h3>
      </div>
      <div>
        <h4 class="textThree">Tu cuenta de ahorros Pyme</h4>
      </div>
      <div>
        <h6 class="textFour">Menos burocracia <br>
          <span class="outline">CONTROL</span><br> para su negocio</h6>
      </div>
    </div>

    <div class="mobile-box">
      <div>
        <h3 class="titlePrimary">
          <img class="logo-u-mobile" src="../../../assets/images/icons/ic-personas.png">
          Unipersonal
        </h3>
        <div class="description">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem aliquid placeat saepe in.
          Unde vitae, debitis cum iure nam fuga quasi sunt provident aut.
        </div>
        <div>
          <h3 class="titleSecondary">
            Ésto es lo que va a necesitar:
          </h3>
        </div>
        <div>
          <ul class="item">
            <li class="">
              Datos de solicitud
            </li>
            <li>
              Documentacion legal obligatoria
            </li>
            <li>
              Residencia fiscal
            </li>
            <li>
              Declaracion de fondo
            </li>
          </ul>
        </div>
        <button class="btnSecondary" routerLink="/onboarding/unipersonal">
          Comenzar solicitud
        </button>
      </div>
    </div>

    <!-- box 2 -->
    <div class="mobile-box">
      <div>
        <h3 class="titlePrimary">
          <img class="logo-e-mobile" src="../../../assets/images/icons/ic-personas.png">
          Empresa
        </h3>
        <div class="description">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem aliquid placeat saepe in.
          Unde vitae, debitis cum iure nam fuga quasi sunt provident aut.
        </div>
        <div>
          <h3 class="titleSecondary">
            Ésto es lo que va a necesitar:
          </h3>
        </div>
        <div>
          <ul class="item">
            <li class="">
              Datos de solicitud
            </li>
            <li>
              Documentacion legal obligatoria
            </li>
            <li>
              Residencia fiscal
            </li>
            <li>
              Declaracion de fondo
            </li>
          </ul>
        </div>
        <div>
          <button class="btnSecondary" routerLink="/onboarding/empresa">
            Comenzar solicitud
          </button>
        </div>

      </div>
    </div>
</div>
