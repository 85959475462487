import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {

  typeOfOnboarding: any;

  constructor(
    router: Router
    ) {
    if (!router.getCurrentNavigation().extras.state) {
      router.navigate(['/landing']);
    } else {
      this.typeOfOnboarding = router.getCurrentNavigation().extras.state;
    }
  }

  ngOnInit(): void {

  }

}
