import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export const disallowZeroPattern = /^(?:[1-9]\d*)$/;

export const regexValidator = (regex: RegExp, errorName: string): ValidatorFn => {
  return (control: AbstractControl): {[key: string]: any} => {
    const valid = control.value === '' || regex.test(control.value);
    const newErrorObject = {};
    newErrorObject[errorName] = { value: control.value };

    return valid ? null : { ...control.errors, ...newErrorObject };
  };
};
