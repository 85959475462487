import { Component, OnInit } from '@angular/core';

import { View } from '../../../../enums';

@Component({
  selector: 'app-legal-representative-terms',
  templateUrl: './legal-representative-terms.component.html',
  styleUrls: ['./legal-representative-terms.component.scss']
})
export class LegalRepresentativeTermsComponent implements OnInit {

  public view = View.LEGAL_REPRESENTATIVE_TERMS_AND_CONDITIONS;

  constructor () { }

  ngOnInit(): void {
  }

}
