import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ApplicationService, FormStateService, ViewManagerService } from '../../../services';
import { View } from '../../../enums';
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  @Input() view: View;

  public termsAplicantView = View.TERMS_AND_CONDITIONS;
  public legalRepresentativeTermsView = View.LEGAL_REPRESENTATIVE_TERMS_AND_CONDITIONS;

  public loading = false;
  public termsAndConditions: FormGroup;

  public terms = `FINANCIERA DANN REGIONAL (producto Iris Bank), en cumplimiento con lo indicado en la Ley 1581 de 2012 "por la cual se dictan disposiciones generales para la protección de datos personales" y el Decreto 1377 de 2013 "por el cual se reglamenta parcialmente la Ley 1581 de 2012". Desarrolla la presente política de tratamiento de datos personales así:

DEFINICIONES

La Ley 1266 de 2008, define los siguientes tipos de datos de carácter personal:

a) Titular de la información. Es la persona natural o jurídica a quien se refiere la información que reposa en un banco de datos y sujeto del derecho de hábeas data y demás derechos y garantías a que se refiere la presente ley;
b) Fuente de información. Es la persona, entidad u organización que recibe o conoce datos personales de los titulares de la información, en virtud de una relación comercial o de servicio o de cualquier otra índole y que, en razón de autorización legal o del titular, suministra esos datos a un operador de información, el que a su vez los entregará al usuario final. Si la fuente entrega la información directamente a los usuarios y no, a través de un operador, aquella tendrá la doble condición de fuente y operador y asumirá los deberes y responsabilidades de ambos. La fuente de la información responde por la calidad de los datos suministrados al operador la cual, en cuanto tiene acceso y suministra información personal de terceros, se sujeta al cumplimiento de los deberes y responsabilidades previstas para garantizar la protección de los derechos del titular de los datos;
c) Operador de información. Se denomina operador de información a la persona, entidad u organización que recibe de la fuente datos personales sobre varios titulares de la información, los administra y los pone en conocimiento de los usuarios bajo los parámetros de la presente ley. Por tanto el operador, en cuanto tiene acceso a información personal de terceros, se sujeta al cumplimiento de los deberes y responsabilidades previstos para garantizar la protección de los derechos del titular de los datos. Salvo que el operador sea la misma fuente de la información, este no tiene relación comercial o de servicio con el titular y por ende no es responsable por la calidad de los datos que le sean suministrados por la fuente;
d) Usuario. El usuario es la persona natural o jurídica que, en los términos y circunstancias previstos en la presente ley, puede acceder a información personal de uno o varios titulares de la información suministrada por el operador o por la fuente, o directamente por el titular de la información. El usuario, en cuanto tiene acceso a información personal de terceros, se sujeta al cumplimiento de los deberes y responsabilidades previstos para garantizar la protección de los derechos del titular de los datos. En el caso en que el usuario a su vez entregue la información directamente a un operador, aquella tendrá la doble condición de usuario y fuente, y asumirá los deberes y responsabilidades de ambos;

e) Dato personal. Es cualquier pieza de información vinculada a una o varias personas determinadas o determinables o que puedan asociarse con una persona natural o jurídica. Los datos impersonales no se sujetan al régimen de protección de datos de la presente ley. Cuando en la presente ley se haga referencia a un dato, se presume que se trata de uso personal. Los datos personales pueden ser públicos, semiprivados o privados;

f) Dato público. Es el dato calificado como tal según los mandatos de la ley o de la Constitución Política y todos aquellos que no sean semiprivados o privados, de conformidad con la presente ley. Son públicos, entre otros, los datos contenidos en documentos públicos, sentencias judiciales debidamente ejecutoriadas que no estén sometidos a reserva y los relativos al estado civil de las personas;

g) Dato semiprivado. Es semiprivado el dato que no tiene naturaleza íntima, reservada, ni pública y cuyo conocimiento o divulgación puede interesar no sólo a su titular sino a cierto sector o grupo de personas o a la sociedad en general, como el dato financiero y crediticio de actividad comercial o de servicios a que se refiere el Título IV de la presente ley.

h) Dato privado. Es el dato que por su naturaleza íntima o reservada sólo es relevante para el titular.

i) Agencia de Información Comercial. Es toda empresa legalmente constituida que tenga como actividad principal la recolección, validación y procesamiento de información comercial sobre las empresas y comerciantes específicamente solicitadas por sus clientes, entendiéndose por información comercial aquella información histórica y actual relativa a la situación financiera, patrimonial, de mercado, administrativa, operativa, sobre el cumplimiento de obligaciones y demás información relevante para analizar la situación integral de una empresa. Para los efectos de la presente ley, las agencias de información comercial son operadores de información y fuentes de información.

j) Información financiera, crediticia, comercial, de servicios y la proveniente de terceros países. se entenderá por información financiera, crediticia, comercial, de servicios y la proveniente de terceros países, aquella referida al nacimiento, ejecución y extinción de obligaciones dinerarias, independientemente de la naturaleza del contrato que les dé origen.

La Ley 1581 de 2012 define los siguientes roles:

Administrador de la base de datos personales: funcionario o encargado que tiene a cargo y realiza el tratamiento a una o más bases de datos que tiene información personal.
Encargado del tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de datos personales por cuenta del Responsable del Tratamiento.
Responsable del tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos.

Otras definiciones de La Ley 1581 de 2012:

Autorización: Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de datos personales.
Base de Datos: Conjunto organizado de datos personales que sea objeto de Tratamiento.
Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales como, entre otros, la recolección, el almacenamiento, el uso, la circulación o supresión de esa clase de información.
Titular: Persona natural cuyos datos personales sean objeto de Tratamiento.

DEBERES DEL RESPONSABLE DEL TRATAMIENTO DE LA INFORMACIÓN

•        Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data;
•        Solicitar y conservar, en las condiciones previstas en la Ley 1581 de 2012, copia de la respectiva autorización otorgada por el Titular;
•        Informar debidamente al Titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la autorización otorgada;
•        Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento;
•        Garantizar que la información que se suministre al Encargado del Tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible;
•        Actualizar la información, comunicando de forma oportuna al Encargado del Tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a este se mantenga actualizada;
•        Rectificar la información cuando sea incorrecta y comunicar lo pertinente al Encargado del Tratamiento;
•        Suministrar al Encargado del Tratamiento, según el caso, únicamente datos cuyo Tratamiento esté previamente autorizado de conformidad con lo previsto en la citada ley;
•        Exigir al Encargado del Tratamiento el respeto, en todo momento, a las condiciones de seguridad y privacidad de la información del Titular;
•        Tramitar las consultas y solicitudes formuladas en los términos señalados en la ley.
•        Adoptar las políticas y procedimientos necesarios para garantizar el adecuado cumplimiento de la ley y en especial, para la atención de consultas y solicitudes.
•        Informar al Encargado del Tratamiento cuando determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la solicitud y no haya finalizado el trámite respectivo.
•        Informar a solicitud del Titular sobre el uso dado a sus datos.
•        Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares.
•        Cumplir las instrucciones y requerimientos que imparta la autoridad competente.

DERECHOS DE LOS TITULARES

La Ley 1581 de 2012 establece que los Titulares de los datos personales tendrán los siguientes derechos:

•        Conocer, actualizar y rectificar sus datos personales frente a FINANCIERA DANN REGIONAL (Producto Iris Bank) como Responsable y Encargado del Tratamiento. Este derecho se podrá ejercer entre otros ante datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido autorizado.
•        Solicitar prueba de la autorización otorgada a FINANCIERA DANN REGIONAL como Responsable, salvo cuando expresamente se exceptúe como requisito para el Tratamiento, de conformidad con lo previsto en el artículo 10 de la Ley 1581 de 2012.
•        Ser informado por FINANCIERA DANN REGIONAL como Responsable del tratamiento o Encargado, previa solicitud, respecto del uso que les ha dado a los datos personales del Titular.
•        Presentar ante la Superintendencia Financiera de Colombia quejas por infracciones a lo dispuesto en la Ley 1581 de 2012 y las demás normas que la modifiquen, adicionen o complementen.
•        Revocar la autorización y/o solicitar la supresión del dato personal cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la Superintendencia Financiera de Colombia haya determinado que en el Tratamiento el Responsable o Encargado han incurrido en conductas contrarias a la Ley 1581 de 2012 y a la Constitución.
•        Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento. La información solicitada por el titular podrá ser suministrada por cualquier medio, incluso a través de medios electrónicos.
•        Decreto Único del Sector Industria y Comercio – Decreto 1074 de 2015 define que los Responsables deberán conservar prueba de la autorización otorgada por los Titulares de datos personales para el Tratamiento de estos.
•        Los anteriores derechos se entienden, sin perjuicio de las obligaciones legales, administrativas, de seguridad y diligencia que FINANCIERA DANN REGIONAL debe tener en ejecución.

FINALIDADES Y TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS PERSONALES

FINANCIERA DANN REGIONAL realizará el tratamiento (recolección, almacenamiento, uso, entre otros) de los datos personales de acuerdo con lo establecido por la ley y los mandatos judiciales o administrativos, así como por lo dispuesto en las autorizaciones cuando corresponda para cumplir en especial las actividades propias de su función.

El tratamiento de los datos personales se podrá realizar a través de medios físicos, automatizados o digitales de acuerdo con el tipo y forma de recolección de la información personal.

FINANCIERA DANN REGIONAL realizará el tratamiento de los datos personales en cumplimiento de sus funciones legales o judiciales y de acuerdo con las finalidades establecidas en las autorizaciones respectivas cuando corresponda. De manera particular para las siguientes finalidades:

En relación con la naturaleza y las funciones

Así mismo, FINANCIERA DANN REGIONAL podrá enviar o intercambiar información con autoridades administrativas o judiciales, en ejercicio de sus funciones.

DATOS SENSIBLES.

El Titular tiene derecho a optar por no suministrar cualquier información sensible que eventualmente pueda solicitarse, relacionada, entre otros, con datos sobre su origen racial o étnico, la pertenencia a sindicatos, organizaciones sociales o de derechos humanos, convicciones políticas, religiosas, de la vida sexual, biométricos o datos de salud, salvo que se requieran para el ejercicio de funciones legales o cuando exista orden de autoridad administrativa o judicial.

DATOS DE MENORES DE EDAD.

FINANCIERA DANN REGIONAL podrá tratar datos de menores de edad conforme al interés superior de los niños, niñas y adolescentes (NNA), y respetando sus derechos fundamentales. La autorización debe ser otorgada por personas que estén facultadas para representar los NNA. El representante de los NNA deberá garantizarles el derecho a ser escuchados y valorar su opinión del tratamiento teniendo en cuenta la madurez, autonomía y capacidad de los NNA para entender el asunto

CONSULTAS Y RECLAMOS

Para realizar consultas sobre la información personal que reposa del titular en las bases de datos de FINANCIERA DANN REGIONAL o solicitudes encaminadas a corregir, actualizar, rectificar, suprimir o revocar la autorización, la entidad cuenta con canales de atención presencial, virtual y telefónico que garantizan la continuidad en la prestación de sus servicios, por los cuales los ciudadanos obtienen información completa, clara y precisa sobre las condiciones de tiempo, modo y lugar en que se atenderán o gestionarán sus requerimientos o trámites.

Canal        Ubicación        Horario de Atención
Atención Presencial                Lunes a viernes (días hábiles)
de 7:30 a.m. a 5 p.m.
Atención Virtual        ¿Vamos a tener un correo exclusivo para tratamiento de datos?        Activo las 24 horas.

Atención Telefónica
        Lunes a viernes (días hábiles)
de 7:30 a.m. a 5:00 pm.
jornada continua.

Toda Solicitud recibida fuera del horario establecido para la atención al público, se entenderá recibida el día hábil siguiente

Las consultas se absolverán en un término máximo de diez (10) días hábiles, contados a partir del día siguiente de la fecha de su recepción.

Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado, expresando los motivos de la demora y señalando la fecha en que será atendida, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.

El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.

El ciudadano cuenta con canales de atención presencial, virtual y telefónico que garantizan la continuidad en la prestación de sus servicios, por los cuales los ciudadanos obtienen información completa, clara y precisa sobre las condiciones de tiempo, modo y lugar en que se atenderán o gestionarán sus requerimientos o trámites.

REVOCATORIA Y/O SUPRESIÓN DE DATOS

El Titular de datos personales tiene derecho en todo momento a solicitar a FINANCIERA DANN REGIONAL, la revocatoria y/o supresión (eliminación) de sus datos personales, siempre y cuando no se trate de datos utilizados para las actividades propias de la misión de la Entidad, los cuales se regirán por la normativa especial.

El derecho de supresión no es un derecho absoluto y FINANCIERA DANN REGIONAL como responsable del tratamiento de datos personales puede negar o limitar el ejercicio de este cuando:

•        El titular de los datos tenga el deber legal o contractual de permanecer en la base de datos.
•        La eliminación de datos obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la actualización de sanciones administrativas.
•        Los datos sean necesarios para proteger los intereses jurídicamente tutelados del titular; para realizar una acción en función del interés público, o para cumplir con una obligación legalmente adquirida por el titular.
•        Los datos sean datos utilizados en las actividades de la misión de la entidad.
•        No obstante, lo anterior, se garantizará que los datos se utilicen exclusivamente para el fin solicitado y se dé a la información la reserva correspondiente.
•        La revocatoria y/o supresión procederá cuando la autoridad competente haya determinado que en el Tratamiento el Responsable o Encargado han incurrido en conductas contrarias a la Ley 1581 de 2012 y a la Constitución, por muerte del titular, y/o por orden judicial.

DISPOSICIONES GENERALES

FINANCIERA DANN REGIONAL podrá realizar la transferencia de datos a otros responsables del tratamiento cuando se realice en el ejercicio de sus funciones, obligaciones, o cuando así esté autorizado por el titular de la información o por la ley o por un mandato administrativo o judicial.

FINANCIERA DANN REGIONAL podrá enviar o transmitir datos a uno o varios encargados ubicados dentro o fuera del territorio de la República de Colombia en los siguientes casos:

a.        Para el ejercicio de sus funciones.
b.        Cuando cuente con autorización de titular.
c.        Cuando sin contar con la autorización exista entre el responsable y el encargado un contrato de transmisión de datos.

La ley exceptúa del régimen de protección de datos personales a (i) los archivos y las bases de datos pertenecientes al ámbito personal o doméstico; (ii) los que tienen por finalidad la seguridad y la defensa nacionales, la prevención, detección, monitoreo y control del lavado de activos y el financiamiento del terrorismo, (iii) los que tengan como fin y contengan información de inteligencia y contrainteligencia, (iv) los de información periodística y otros contenidos editoriales, (v) los regulados por la Ley 1266 de 2008 (información financiera y crediticia, comercial, de servicios y proveniente de terceros países) y (vi) los regulados por la Ley 79 de 1993 (sobre censos de población y vivienda).

La política establecida por FINANCIERA DANN REGIONAL respecto al tratamiento de Datos Personales podrá ser modificada en cualquier momento. Toda modificación se realizará con apego a la normatividad legal vigente, y la misma entrará en vigencia y tendrá efectos desde su publicación a través de los mecanismos dispuestos por la entidad para que los titulares conozcan la política de tratamiento de la información y los cambios que se produzcan en ella.

`

  constructor (
    private applicationService: ApplicationService,
    private formState: FormStateService,
    private router: Router,
    private viewManager: ViewManagerService,
   ) { }

  ngOnInit(): void {
    if (this.view === View.TERMS_AND_CONDITIONS) {
      this.termsAndConditions = new FormGroup({
        Terminos_Y_Condiciones: new FormControl('', [Validators.requiredTrue]),
      });
    }
    if (this.view === View.LEGAL_REPRESENTATIVE_TERMS_AND_CONDITIONS) {
      this.termsAndConditions = new FormGroup({
        Terminos_Y_Condiciones: new FormControl('', [Validators.requiredTrue]),
        residencia: new FormControl('', [Validators.required]),
      });
    }

    this.terms = this.terms.replace(/\n/g, '<br/>');
  }

  checkError(controlName: string, errorName: string) {
    return this.termsAndConditions.controls[controlName].hasError(
      errorName
    );
  }

  onSubmit() {
    if (this.view === View.TERMS_AND_CONDITIONS) {
      this.loading = true;
      //this.applicationService.createApplication().toPromise().then(() => {
        this.loading = false;
        this.viewManager.setView({ view: View.APPLICANT_DATA });
      //});
    } else {
      this.loading = true;
      const formGroup = this.formState.legalRepresentativeIdentityConfirmationFormState;
      formGroup.addControl('Terminos_Y_Condiciones', this.termsAndConditions.controls.Terminos_Y_Condiciones);
      formGroup.addControl('Residencia', this.termsAndConditions.controls.residencia);
      this.viewManager.setView({ view: View.DATA_VERIFICATION });
      // TODO Coneccion con BE

    }
  }

  moveToPrev() {
    if (this.view === View.TERMS_AND_CONDITIONS) {
    this.router.navigate(['landing']);
  } else {
      this.viewManager.setView({ view: View.LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION });
    }
  }

}
