import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import  config  from './config';

const poolData = config.poolData;

@Injectable({
  providedIn: 'root'
})

export class RequestInformationService {

  constructor(private api: ApiService) { }

  summaryRequest(){
    let DocumentUpload = new Observable();
    try {
      let resp = this.api.get(poolData.url);
      return resp;
    }
    catch (e) {
      console.log(e);
    }
    return DocumentUpload;
  }
}