import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ViewManagerService } from '../../../../services';
import { View } from '../../../../enums';

@Component({
  selector: 'app-regulations',
  templateUrl: './regulations.component.html',
  styleUrls: ['./regulations.component.scss']
})
export class RegulationsComponent implements OnInit {

  constructor (
    private viewManager: ViewManagerService,
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.viewManager.setView({ view: View.DATA_VERIFICATION });
  }

  next() {
    this.viewManager.setView({ view: View.VERIFICATION_COMPLETED });
  }
}
