import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FaqsComponent } from '../app/components/faqs/faqs.component';
import { LandingComponent } from './components/landing/landing.component';
import { DocumentationComponent } from './components/landing/documentation/documentation.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { LegalRepresentativeIdentityConfirmationComponent } from './components/onboarding/views/legal-representative-identity-confirmation/legal-representative-identity-confirmation.component';
import { ResumeProcessComponent} from '../app/components/onboarding/resume/resume-process/resume-process.component';
import { LoadingComponent} from '../app/shared/loading/loading.component';
import { CompanyFormComponent} from '../app/components/onboarding/views/company-form/company-form.component'
import { LegalRepresentativeFormComponent} from '../app/components/onboarding/views/legal-representative-form/legal-representative-form.component'

const routes: Routes = [
  { path: 'landing/documentation', component: DocumentationComponent },
  { path: 'landing', component: LandingComponent },
  { path: 'onboarding/:type', component: OnboardingComponent },
  { path: 'preguntas-frecuentes', component: FaqsComponent },
  { path: 'legalr', component: LegalRepresentativeIdentityConfirmationComponent },
  { path: 'resume-process', component: ResumeProcessComponent },
  { path: 'loading', component: LoadingComponent },
  { path: 'upload', component: CompanyFormComponent }, //se debe eliminar
  { path: 'legal', component: LegalRepresentativeFormComponent }, //se debe eliminar
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
