import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { ViewModel } from '../../interfaces';
import { View } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class ViewManagerService {
  private viewSubject = new BehaviorSubject<ViewModel>({ view: View.TERMS_AND_CONDITIONS });
  // private viewSubject = new BehaviorSubject<ViewModel>({ view: View.COMPANY_DATA });
  readonly view$ = this.viewSubject.asObservable();

// TERMS_AND_CONDITIONS
// APPLICANT_DATA
// COMPANY_DATA
// LEGAL_REPRESENTATIVE_DATA
// APPLICATION_COMPLETED

  constructor () { }

  public setView(params: ViewModel): void {
    this.viewSubject.next(params);
  }
}
