import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ApiService, FormStateService, ViewManagerService } from '../../../../services';
import { JWTServiceService } from '../../../../services/JWT/jwt-service.service';
import { View } from '../../../../enums';
// import { disallowZeroPattern, regexValidator, } from '../../../../helpers/custom-form-validators';
@Component({
  selector: 'app-legal-representative-identity-confirmation',
  templateUrl: './legal-representative-identity-confirmation.component.html',
  styleUrls: ['./legal-representative-identity-confirmation.component.scss']
})
export class LegalRepresentativeIdentityConfirmationComponent implements OnInit {

  public view = View.LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION;
  public legalRepresentativeIdentityConfirmationFormGroup: FormGroup;
  public loading = false;
  public maxDate: any;

  constructor(
    private formState: FormStateService,
    private viewManager: ViewManagerService,
  ) {
    this.maxDate = moment().subtract(1, 'days').format();
  }

  ngOnInit(): void {
    this.legalRepresentativeIdentityConfirmationFormGroup =
      this.formState.legalRepresentativeIdentityConfirmationFormState || new FormGroup({
        Fecha_De_Expedicion: new FormControl('', [Validators.required]),

        Celular: new FormControl('', [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9),
        ]),
      });
  }

  checkError(controlName: string, errorName: string) {
    return this.legalRepresentativeIdentityConfirmationFormGroup.controls[controlName].hasError(
      errorName
    );
  }

  numbersOnly(event: KeyboardEvent): boolean {
    const { key } = event;

    // check if key property is present
    return (
      (key &&
        // allow numbers
        /\d/.test(key)) ||
      // allow backspace, delete, tab, enter and arrows
      [
        'Backspace',
        'Delete',
        'Tab',
        'Enter',
        'ArrowLeft',
        'ArrowRight',
      ].includes(key)
    );
  }

  onSubmit() {
    this.loading = true;
    const formData = { ...this.legalRepresentativeIdentityConfirmationFormGroup.value };
    this.viewManager.setView({ view: View.LEGAL_REPRESENTATIVE_TERMS_AND_CONDITIONS });
    formData.Fecha_De_Expedicion = formData.Fecha_De_Expedicion.format(
      moment.HTML5_FMT.DATE
    );
    this.formState.legalRepresentativeIdentityConfirmationFormState = this.legalRepresentativeIdentityConfirmationFormGroup;

  }

}
