export enum View   {
  APPLICANT_DATA = 'applicant-data',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  COMPANY_DATA = 'company-data',
  LEGAL_REPRESENTATIVE_DATA = 'legal-representative-data',
  LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION = 'legal-representative-identity-confirmation',
  LEGAL_REPRESENTATIVE_TERMS_AND_CONDITIONS = 'legal-representative-terms',
  DATA_VERIFICATION = 'data-verification',
  REGULATIONS = 'regulations',
  APPLICATION_COMPLETED = 'application-completed',
  VERIFICATION_COMPLETED = 'verification-completed',
}

