
  <ul *desktopOnly class="justify-content-center list-unstyled">
    <li [ngClass]="{ active: activeSteps >= i + 1 }" *ngFor="let object of createRange(amountOfSteps); index as i;">
      <span></span>
    </li>
  </ul>
  <!-- <div class="col-md-11 stepper">
    <mat-horizontal-stepper *desktopOnly linear #stepper>
      <mat-step [stepControl]="firstFormGroup">
          <ng-template  matStepLabel>Datos de la solicitud</ng-template>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
          <ng-template matStepLabel>Documentación legal obligatoria</ng-template>
      </mat-step>
      <mat-step [stepControl]="thirdFormGroup">
          <ng-template matStepLabel>Datos del representante legal</ng-template>
      </mat-step>
    </mat-horizontal-stepper>
  </div> -->
  

 

  
  <ul *mobileOnly class="justify-content-center list-unstyled">
    <li [ngClass]="{ active: activeSteps >= i + 1 }" *ngFor="let object of createRange(amountOfSteps); index as i;">
      <span></span>
    </li>
  </ul>
