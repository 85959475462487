<div *desktopOnly class="row justify-content-center no-gutters">

  <form [formGroup]="businessInformationFormGroup" (ngSubmit)="onSubmit()"
    class="row col-12 col-md-8 justify-content-center">
    <div class="documents col-12 no-gutters">
      <div class="col-12 row no-gutters file-row">
        <div class="col-12 file-names">
          Cámara de Comercio o Certificado de existencia y representación legal
        </div>
        <div class="col-12 row no-gutters align-items-center">
          <div class="file-upload-box row no-gutters col-12">
            <div class="col-12">
              <!-- <span >
                ic-upload
              </span> -->
              <input type="text" class="col-12 upload-input" placeholder="ELIGE UN ARCHIVO O ARASTRA AQUI"
                value="{{fileNameCamara}}">

              <input class="" id="input-file-camara" formControlName="uploadFileCamara" type="file"
                onclick="value = null" (change)="fileCamara($event)" />
              <!-- <i class="material-icons-upload">ic-upload</i> -->

              <button mat-button class="clear" *ngIf="fileNameCamara" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearCamara()">
                <span class="material-icons">
                  close
                </span>
              </button>
            </div>
          </div>
          <mat-error *ngIf="checkError('uploadFileCamara', 'invalid')">
            El archivo debe ser un PDF
          </mat-error>

          <div class="col-12 see-more">
            <a href="https://drive.google.com/file/d/1TzSR9lFrAq24rcFsX5yegVCcKdA-QjSt/view?usp=sharing"
              target="_blank">
              <span class="material-icons">
                info
              </span>
              Ver ejemplo
            </a>
          </div>
        </div>
      </div>

      <div class="note">
        <p class="">
          Si la relación de socios se detalla en la Cámara de Comercio o Certificado de existencia y representación, No
          será obligatorio la presentación de Composición accionaria. Si existen socios PJ con participación mayor al
          5%, se deberá contar con la relación de socios hasta llegar al último socio persona natural.
        </p>
      </div>

      <div class="col-12 row no-gutters file-row">
        <div class="col-12 file-names">
          <strong>
            Composición Acccionaria
          </strong>
          <span>
            (Opcional)
          </span>
        </div>
        <div class="col-12 row no-gutters align-items-center">
          <div class="file-upload-box row no-gutters col-12">
            <div class="col-12">
              <input type="text" class="col-12 upload-input" placeholder="ELIGE UN ARCHIVO O ARASTRA AQUI"
                value="{{fileNameAccionaria}}">
              <input class="" id="input-file-accionaria" formControlName="uploadFileAccionaria" type="file"
                onclick="value = null" (change)="fileAccionaria($event)" />
              <button mat-button class="clear" *ngIf="fileNameAccionaria" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearAccionaria()">
                <span class="material-icons">
                  close
                </span>
              </button>
            </div>
          </div>

          <mat-error *ngIf="checkError('uploadFileAccionaria', 'invalid')">
            El archivo debe ser un PDF
          </mat-error>

          <div class="col-12 see-more">
            <a href="https://drive.google.com/file/d/1dmtD7Do1sq6c3mMIZD0KxRH0sBKZvFex/view?usp=sharing"
              target="_blank">
              <span class="material-icons">
                info
              </span>
              Ver ejemplo
            </a>
          </div>
        </div>
      </div>
      <p class="note">
        <strong>
          Importante: <br>
        </strong>
        La información solicitada se usará con el fin de confirmar los requisitos para la apertura de su cuenta.
        Financiera Dann Regional garantiza la confidencialidad de su información.
        <br>
        <br>
        Los documentos enviados serán procesados según nuestras normas de validación.
        <br>
        <br>
        En algunos casos podemos requerir información adicional que le solicitaremos vía email.
      </p>
    </div>


    <div class="row justify-content-center-form">
      <div class="col-6">
        <button class="btnSecondary-outline" type="button"  (click)="moveToPrev()">
          <span class="material-icons">
            arrow_back_ios
          </span>
          Anterior
        </button>
      </div>
      <div class="col-6">
        <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
        <button class="btnSecondary" type="submit"  [disabled]="!businessInformationFormGroup.valid" >
          Siguiente
          <span class="material-icons">
            arrow_forward_ios
          </span>
        </button>
      </div>
    </div>

    <!-- <div class="col-12 col-md-6 order-12 order-md-1 buttons">
      <button class="btn-primary-outline" (click)="moveToPrev()" type="button">
        <span class="material-icons">
          arrow_back_ios
        </span>
        Volver
      </button>
    </div>
    <div class="col-12 col-md-6 order-1 order-md-12 d-flex justify-content-end buttons">
      <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
      <button class="btn-primary" type="submit" [disabled]="!businessInformationFormGroup.valid">
        Siguiente
        <span class="material-icons">
          arrow_forward_ios
        </span>
      </button>
    </div> -->
  </form>
</div>


<div *mobileOnly class="row justify-content-center companyMobile">

  <form [formGroup]="businessInformationFormGroup" (ngSubmit)="onSubmit()"
    class="row col-12 col-md-8 justify-content-center">
    <div class="documents col-12 no-gutters">
      <div class="col-12 row no-gutters file-row">
        <div class="col-10 nameMobile">
          Cámara de Comercio o Certificado de existencia y representación legal
        </div>
        <div class="col-12 row no-gutters align-items-center">
          <div class="file-upload-box row no-gutters col-12">
            <div class="col-10">
              
              <input type="text" class="col-12 upload-input" placeholder="ELIGE UN ARCHIVO O ARASTRA AQUI"
                value="{{fileNameCamara}}">
              <input class="" id="input-file-camara" formControlName="uploadFileCamara" type="file"
                onclick="value = null" (change)="fileCamara($event)" />
                <button mat-button class="clear" *ngIf="fileNameCamara" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearCamara()">
                <span class="material-icons">
                  close
                </span>
              </button>
            </div>
          </div>
          <mat-error *ngIf="checkError('uploadFileCamara', 'invalid')">
            El archivo debe ser un PDF
          </mat-error>

          <div class="col-12 see-more">
            <a href="https://drive.google.com/file/d/1TzSR9lFrAq24rcFsX5yegVCcKdA-QjSt/view?usp=sharing"
              target="_blank">
              <span class="material-icons">
                info
              </span>
              Ver ejemplo
            </a>
          </div>
        </div>
      </div>

      <div class="note">
        <p class="">
          Si la relación de socios se detalla en la Cámara de Comercio o Certificado de existencia y representación, No
          será obligatorio la presentación de Composición accionaria. Si existen socios PJ con participación mayor al
          5%, se deberá contar con la relación de socios hasta llegar al último socio persona natural.
        </p>
      </div>

      <div class="col-12 row no-gutters file-row">
        <div class="col-10 nameMobile">
          <strong>
            Composición Acccionaria 
          </strong>
          <span>
            (Opcional)
          </span>
        </div>
        <div class="col-12 row no-gutters align-items-center">
          <div class="file-upload-box row no-gutters col-12">
            <div class="col-10">
              <input type="text" class="col-12 upload-input" placeholder="ELIGE UN ARCHIVO O ARASTRA AQUI"
                value="{{fileNameAccionaria}}">
              <input class="" id="input-file-accionaria" formControlName="uploadFileAccionaria" type="file"
                onclick="value = null" (change)="fileAccionaria($event)" />
              <button mat-button class="clear" *ngIf="fileNameAccionaria" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearAccionaria()">
                <span class="material-icons">
                  close
                </span>
              </button>
            </div>
          </div>

          <mat-error *ngIf="checkError('uploadFileAccionaria', 'invalid')">
            El archivo debe ser un PDF
          </mat-error>

          <div class="col-12 see-more">
            <a href="https://drive.google.com/file/d/1dmtD7Do1sq6c3mMIZD0KxRH0sBKZvFex/view?usp=sharing"
              target="_blank">
              <span class="material-icons">
                info
              </span>
              Ver ejemplo
            </a>
          </div>
        </div>
      </div>
      <p class="note">
        <strong>
          Importante: <br>
        </strong>
        La información solicitada se usará con el fin de confirmar los requisitos para la apertura de su cuenta.
        Financiera Dann Regional garantiza la confidencialidad de su información.
        <br>
        <br>
        Los documentos enviados serán procesados según nuestras normas de validación.
        <br>
        <br>
        En algunos casos podemos requerir información adicional que le solicitaremos vía email.
      </p>
    </div>


    <!-- <div class="row justify-content-center-form">
      <div class="col-5">
        <button class="btnSecondaryOutline" type="button" (click)="moveToPrev()">
          <span class="material-icons">
            arrow_back_ios
          </span>
          Anterior
        </button>
      </div>
      <div class="col-5">
        <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
        <button class="btnSecondaryMobile" type="submit" [disabled]="!businessInformationFormGroup.valid" >
          Siguiente
          <span class="material-icons">
            arrow_forward_ios
          </span>
        </button>
      </div>
    </div> -->

    <div class="row justify-content-center-form">
      

      <div class="col-12">
        <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>

        <button class="btnSecondaryMobile"  [disabled]="!businessInformationFormGroup.valid" type="submit">
          <span >
            siguiente
          </span>
          <!-- Siguiente -->
          <span class="material-icons">
            arrow_forward_ios
          </span>
        </button>
      </div>
    </div>
  </form>
</div>