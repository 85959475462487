import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Angular Material Components
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatStepperModule} from '@angular/material/stepper';


import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {​​​​​​​​  HttpClientModule, HTTP_INTERCEPTORS }​​​​​​​​ from'@angular/common/http';

import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

// Directives
import { DesktopOnlyDirective } from './directives/desktop-only.directive';
import { MobileOnlyDirective } from './directives/mobile-only.directive';
import { ClickRightDirective } from './directives/click-right.directive';

// Components
import { ApplicantFormComponent } from './components/onboarding/views/applicant-form/applicant-form.component';
import { CompanyFormComponent } from './components/onboarding/views/company-form/company-form.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { HeaderComponent } from './components/header/header.component';
import { LandingComponent } from './components/landing/landing.component';
import { LegalRepresentativeFormComponent } from './components/onboarding/views/legal-representative-form/legal-representative-form.component';
import { NotificationsComponent } from './components/onboarding/notifications/notifications.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { PersonalDataFormComponent } from './components/onboarding/personal-data-form/personal-data-form.component';
import { StepperComponent } from './components/onboarding/stepper/stepper.component';
import { TermsConditionsComponent } from './components/onboarding/terms-conditions/terms-conditions.component';
import { TitlesComponent } from './components/onboarding/titles/titles.component';
import { DocumentationComponent } from './components/landing/documentation/documentation.component';
import { TermsComponent } from './components/onboarding/views/terms/terms.component';
import { LegalRepresentativeIdentityConfirmationComponent } from './components/onboarding/views/legal-representative-identity-confirmation/legal-representative-identity-confirmation.component';
import { LegalRepresentativeTermsComponent } from './components/onboarding/views/legal-representative-terms/legal-representative-terms.component';
import { DataVerificationComponent } from './components/onboarding/views/data-verification/data-verification.component';
import { RegulationsComponent } from './components/onboarding/views/regulations/regulations.component';
import { ResumeProcessComponent } from './components/onboarding/resume/resume-process/resume-process.component';
import { BoxComponent } from './components/onboarding/box/box.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { TokenInterceptor } from '../../src/app/helpers/token.interceptor'

@NgModule({
  declarations: [
    AppComponent,
    DesktopOnlyDirective,
    MobileOnlyDirective,
    NotificationsComponent,
    TermsConditionsComponent,
    FaqsComponent,
    LandingComponent,
    PersonalDataFormComponent,
    StepperComponent,
    OnboardingComponent,
    HeaderComponent,
    TitlesComponent,
    LegalRepresentativeFormComponent,
    CompanyFormComponent,
    ApplicantFormComponent,
    DocumentationComponent,
    TermsComponent,
    LegalRepresentativeIdentityConfirmationComponent,
    LegalRepresentativeTermsComponent,
    DataVerificationComponent,
    RegulationsComponent,
    ClickRightDirective,
    ResumeProcessComponent,
    BoxComponent,
    LoadingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    HttpClientModule,
  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
