import { Component, OnInit } from '@angular/core';

import { View } from '../../../../enums';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  public view = View.TERMS_AND_CONDITIONS;

  constructor () { }

  ngOnInit(): void {
  }

}
