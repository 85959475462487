import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { DocumentsModel } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class FormStateService {

  public applicantDataFormState: FormGroup;
  public companyDataFormState: FormGroup;
  public companyDataFiles: DocumentsModel;
  public legalRepresentativeDataFormState: FormGroup;
  public legalRepresentativeIdentityConfirmationFormState: FormGroup;

  constructor () { }
}
