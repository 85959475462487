<!-- <div class="container"> -->
<div class="row d-flex justify-content-center">
  <form [formGroup]="legalRepresentativeIdentityConfirmationFormGroup" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline" class="date-picker col-12 col-md-6" [hideRequiredMarker]="true">
      <mat-label>Fecha de expedición del documento</mat-label>
      <input matInput [max]="maxDate" [matDatepicker]="picker" formControlName="Fecha_De_Expedicion" required>
      <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="checkError('Fecha_De_Expedicion', 'required')">
        Este campo es <strong>requerido</strong>
      </mat-error>
      <mat-error
        *ngIf="legalRepresentativeIdentityConfirmationFormGroup.controls.Fecha_De_Expedicion.status == 'INVALID'">Fecha
        inválida</mat-error>
    </mat-form-field>

    <div class="col-12 col-md-6 tel-box">
      <span class="flag">
        <img src="assets/images/colombia.svg" alt="Colombia">
      </span>
      <mat-form-field appearance="outline" class="telephone">
        <mat-label>Celular</mat-label>
        <span matPrefix>
          (+57) 3 &nbsp;
        </span>
        <input matInput type="tel" name="Celular" formControlName="Celular" placeholder="5555-12345"
          (keydown)="numbersOnly($event)">
        <mat-error *ngIf="checkError('Celular', 'required')">
          Este campo es <strong>requerido</strong>
        </mat-error>
        <mat-error *ngIf="checkError('Celular', 'maxlength')">
          Este campo no puede tener más de 9 caracteres
        </mat-error>
        <mat-error *ngIf="checkError('Celular', 'minlength')">
          Este campo requiere 9 números
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 d-flex justify-content-end">
      <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
      <button class="btn-primary" type="submit" [disabled]="!legalRepresentativeIdentityConfirmationFormGroup.valid">
        Siguiente
        <span class="material-icons">
          arrow_forward_ios
        </span>
      </button>
    </div>
  </form>

</div>
<!-- </div> -->
