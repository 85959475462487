<div class="container">
  <div class="row justify-content-center">
    <div class="col col-md-8 col-11 m-auto">
      <h1>
        Comencemos, esto es lo que vamos a necesitar:
      </h1>

      <ul class="list-unstyled" *ngIf="typeOfOnboarding === 'empresa'">
        <li>
          <div class="title">
            Item 1 Empresa
          </div>

          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium ex in reprehenderit distinctio quaerat voluptatem consequatur voluptate suscipit corrupti sit, veniam laborum iste corporis deleniti ipsam nisi labore recusandae modi.
          </p>
        </li>
        <li>
          <div class="title">
            Item 2 Empresa
          </div>

          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium ex in reprehenderit distinctio quaerat voluptatem consequatur voluptate suscipit corrupti sit, veniam laborum iste corporis deleniti ipsam nisi labore recusandae modi.
          </p>
        </li>
        <li>
          <div class="title">
            Item 3 Empresa
          </div>

          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium ex in reprehenderit distinctio quaerat voluptatem consequatur voluptate suscipit corrupti sit, veniam laborum iste corporis deleniti ipsam nisi labore recusandae modi.
          </p>
        </li>
      </ul>
      <ul class="list-unstyled" *ngIf="typeOfOnboarding === 'unipersonal'">
        <li>
          <div class="title">
            Item 1 Unipersonal
          </div>

          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium ex in reprehenderit distinctio quaerat voluptatem consequatur voluptate suscipit corrupti sit, veniam laborum iste corporis deleniti ipsam nisi labore recusandae modi.
          </p>
        </li>
        <li>
          <div class="title">
            Item 2 Unipersonal
          </div>

          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium ex in reprehenderit distinctio quaerat voluptatem consequatur voluptate suscipit corrupti sit, veniam laborum iste corporis deleniti ipsam nisi labore recusandae modi.
          </p>
        </li>
        <li>
          <div class="title">
            Item 3 Unipersonal
          </div>

          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium ex in reprehenderit distinctio quaerat voluptatem consequatur voluptate suscipit corrupti sit, veniam laborum iste corporis deleniti ipsam nisi labore recusandae modi.
          </p>
        </li>
      </ul>

      <div class="justify-content-between">
        <button class="btn-primary col-12" routerLink="/onboarding/{{typeOfOnboarding}}">
          Siguente
          <span class="material-icons">
            arrow_forward_ios
          </span>
        </button>
        <button class="btn-primary-outline col-12" routerLink="/landing">
          <span class="material-icons">
            arrow_back_ios
          </span>
          Volver
        </button>
      </div>

    </div>
  </div>
</div>

