<header *desktopOnly>
    <div class="logo">
        <img class="logo" src="../../../assets/images/logo2.png">
    </div>
</header>

<div *mobileOnly class="landing-mobile">
    <header>
        <div class="logo">
            <img class="logo-mobile" src="../../../assets/images/logo2.png">
        </div>
    </header>
</div>