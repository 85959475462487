import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jwtDecode from 'jwt-decode';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class JWTServiceService {

  constructor(private router: ActivatedRoute) { }

  public getDecodedAccessToken(token: string): any {
    try{    
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }

  // public getTokenPayload(token) {
  //   return jwtDecode(token);
  // }
}
