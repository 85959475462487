import { Component, OnInit, Input } from '@angular/core';

import { ViewManagerService } from '../../../services';
import { ViewModel } from '../../../interfaces';
import { View } from '../../../enums';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @Input() amountOfSteps: number;
  

  public activeSteps: number;

  constructor (private viewManager: ViewManagerService) { }

  ngOnInit(): void {
    this.viewManager.view$.subscribe((selectedView: ViewModel) => {
      switch (selectedView.view) {
        case View.TERMS_AND_CONDITIONS:
          this.activeSteps = 1;
          break;
        case View.APPLICANT_DATA:
          this.activeSteps = 1;
          break;
        case View.COMPANY_DATA:
          this.activeSteps = 2;
          break;
        case View.LEGAL_REPRESENTATIVE_DATA:
          this.activeSteps = 3;
          break;
        case View.APPLICATION_COMPLETED:
          this.activeSteps = 4;
          break;
        case View.LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION:
          this.activeSteps = 1;
          break;
        case View.LEGAL_REPRESENTATIVE_TERMS_AND_CONDITIONS:
          this.activeSteps = 1;
          break;
        case View.DATA_VERIFICATION:
          this.activeSteps = 2;
          break;
        case View.REGULATIONS:
          this.activeSteps = 3;
          break;
        case View.VERIFICATION_COMPLETED:
          this.activeSteps = 4;
          break;
        default:
          this.activeSteps = 1;
          break;
      }
    });
  }

  createRange(num: number){
    const items = [];
    for (let i = 1; i <= num; i++){
       items.push(i);
    }
    return items;
  }

}
