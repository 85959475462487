<div *desktopOnly class="caseSolicitud">
  <ng-container [ngSwitch]="view">
    <ng-container *ngSwitchCase="viewTypes.APPLICANT_DATA">
      <h2 class="caseSolicitud">Datos de la solicitud</h2>
      <h2 class="caseSolicitudText" *ngIf="view === viewTypes.APPLICANT_DATA">
        Primero necesitamos conocerte
      </h2>

    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.TERMS_AND_CONDITIONS">
      Políticas de uso y tratamiento de datos personales
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.LEGAL_REPRESENTATIVE_TERMS_AND_CONDITIONS">
      Políticas de uso y tratamiento de datos personales
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.COMPANY_DATA">
      Documentación Legal
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.LEGAL_REPRESENTATIVE_DATA">
      Datos del representante legal
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION">
      Vamos a retomar la apertura de su cuenta
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.DATA_VERIFICATION">
      Verificación de datos ingresados
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.REGULATIONS">
      <!-- TODO poner en variable el producto del cual estamos viendo el reglamento? -->
      Reglamento de Producto Cuenta de Ahorros
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.APPLICATION_COMPLETED">
      Solicitud completada
    </ng-container>

  </ng-container>
  <h2 class="text-center" *ngIf="view === viewTypes.LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION">
    Necesitamos confirmar su identidad. Por favor complete los siguientes datos para continuar:
  </h2>
  <h2 class="text-center" *ngIf="view === viewTypes.DATA_VERIFICATION">
    Por favor revise los datos ingresados
  </h2>
</div>

<div *mobileOnly class="caseSolicitudMobile">
  <ng-container [ngSwitch]="view">

    <ng-container *ngSwitchCase="viewTypes.APPLICANT_DATA">
      <br>
      <br>
      <br>
      <br>
      <h2 class="">Datos de la solicitud</h2>
      <h2 class="caseSolicitudText" *ngIf="view === viewTypes.APPLICANT_DATA">
        Primero necesitamos conocerte
      </h2>
      

    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.TERMS_AND_CONDITIONS">
      <br>
      <br>
      <br>
      <br>
      Políticas de uso y tratamiento de datos personales
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.LEGAL_REPRESENTATIVE_TERMS_AND_CONDITIONS">
      Políticas de uso y tratamiento de datos personales
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.COMPANY_DATA">
      <br>
      <br>
      <br>
      <br>
      Documentación Legal
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.LEGAL_REPRESENTATIVE_DATA">
      <br>
      <br>
      <br>
      <br>
      Datos del representante legal
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION">
      Vamos a retomar la apertura de su cuenta
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.DATA_VERIFICATION">
      Verificación de datos ingresados
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.REGULATIONS">
      <!-- TODO poner en variable el producto del cual estamos viendo el reglamento? -->
      Reglamento de Producto Cuenta de Ahorros
    </ng-container>
    <ng-container *ngSwitchCase="viewTypes.APPLICATION_COMPLETED">
      Solicitud completada
    </ng-container>

  </ng-container>
  <h2 class="text-center" *ngIf="view === viewTypes.LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION">
    Necesitamos confirmar su identidad. Por favor complete los siguientes datos para continuar:
  </h2>
  <h2 class="text-center" *ngIf="view === viewTypes.DATA_VERIFICATION">
    Por favor revise los datos ingresados
  </h2>
</div>