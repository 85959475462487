import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CompanyModel, RequestorModel, LegalRepresentativeModel } from '../../interfaces';
import { FormStateService } from '../form-state/form-state.service';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  constructor (private formState: FormStateService, private api: ApiService) {}

  public createApplication() {
    const form = { ...this.formState.applicantDataFormState.value };

    const company: CompanyModel = {
      documentNumber: form.NIT,
      name: form.Company,
    };

    const requestor: RequestorModel = {
      givenName: form.First_Name,
      firstSurname: form.Last_Name,
      secondSurname: form.Segundo_Apellido,
      documentType: form.Tipo_De_Identificacion === 1 ? 'CC' : 'CE',
      documentNumber: form.Numero_De_Identificacion,
      documentIssueDate: form.Fecha_De_Expedicion.format(moment.HTML5_FMT.DATE),
      mobileNumber: `3${form.Celular}`,
      email: form.Email,
      dataProcessingPolicy: true,
    };

    return this.api.post('onboarding/v0/savings-account', { company, requestor });
  }

  public registerLegalRepresentative() {
    const form = { ...this.formState.legalRepresentativeDataFormState.value };

    const legalRepresentative: LegalRepresentativeModel = {
      givenName: form.First_Name,
      firstSurname: form.Last_Name,
      secondSurname: form.Segundo_Apellido,
      documentType: form.Tipo_De_Identificacion === 1 ? 'CC' : 'CE',
      documentNumber: form.Numero_De_Identificacion,
      mobileNumber: `3${form.Celular}`,
      email: form.Email,
      dataProcessingPolicy: true,
      isLegalRepresentative: form.isLegalRepresentative
    };

    return this.api.post('onboarding/v0/savings-account/legal-representative', { ...legalRepresentative }, false);
  }

  public registerLegalRepresentativeConfirmed() {
    const form = { ...this.formState.legalRepresentativeIdentityConfirmationFormState.value };

    const legalRepresentativeConfirmed: LegalRepresentativeModel = {
      givenName: form.First_Name,
      firstSurname: form.Last_Name,
      secondSurname: form.Segundo_Apellido,
      documentType: form.Tipo_De_Identificacion === 1 ? 'CC' : 'CE',
      documentNumber: form.Numero_De_Identificacion,
      mobileNumber: `3${form.Celular}`,
      email: form.Email,
      dataProcessingPolicy: true,
      isLegalRepresentative: form.isLegalRepresentative
    };

    return this.api.put('onboarding/v0/savings-account/legal-representative', { ...legalRepresentativeConfirmed }, false);
  }
}
