import { Component, OnInit } from '@angular/core';

import { View } from '../../../../enums';

@Component({
  selector: 'app-applicant-form',
  templateUrl: './applicant-form.component.html',
  styleUrls: ['./applicant-form.component.scss']
})
export class ApplicantFormComponent implements OnInit {

  public view = View.APPLICANT_DATA;

  constructor () { }

  ngOnInit(): void {
  }

}
