<div *desktopOnly class="container">
  <div class="row justify-content-center">
    <form [formGroup]="termsAndConditions" (ngSubmit)="onSubmit()" class="col col-11 col-xl-8 m-auto">
      <div class="terms">
        <p [innerHTML]="terms"></p>
      </div>

      <section class="row no-gutters tyc justify-content-center">
        <div *ngIf="view === legalRepresentativeTermsView">
          <p>
            Declaro tener residencia legal en:
          </p>
          <mat-form-field appearance="outline" class="col-12" [hideRequiredMarker]="true">
            <mat-label>Seleccionar</mat-label>
            <mat-select formControlName="residencia" name="residencia" required>
              <mat-option [value]="1">
                Colombia
              </mat-option>
              <mat-option [value]="2">
                USA
              </mat-option>
              <mat-option [value]="3">
                      En otros Países
              </mat-option>
            </mat-select>
            <mat-error *ngIf="checkError('residencia', 'required')">
              Este campo es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>

        </div>
        <div class="col-11">
          <mat-checkbox required class="example-margin multiline" formControlName="Terminos_Y_Condiciones">
            Autorizo a Financiera Dann Regional para contactarme o enviarme información relacionada a la solicitud de apertura de
            producto IRIS, a través de los datos de correo electrónico y/o celular que he suministrado. Así mismo declaró que he
            leído los términos y condiciones de uso del producto y tratamiento de datos personales.
          </mat-checkbox>
        </div>
      </section>

      <div class="row justify-content-center-form">
        <div class="col-6">

          <button class="btnSecondary-outline" type="button"  (click)="moveToPrev()" routerLink="/onboarding/unipersonal">
            <span class="material-icons">
              arrow_back_ios
            </span>
            Anterior
          </button>
        </div>

        <div class="col-6">
          <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>

          <button class="btnSecondary"  [disabled]="!termsAndConditions.valid" type="submit">
            Siguiente
            <span class="material-icons">
              arrow_forward_ios
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div *mobileOnly class="mobileTermsAndConditions">
  <!-- <div class="container"> -->
    <div class="row justify-content-center">
      <form [formGroup]="termsAndConditions" (ngSubmit)="onSubmit()">
        <div class="termsMobile">
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi. Amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco laboris nisi.
          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim vis nostrud exercitation ullamco laboris nisi. Amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna.
          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore Amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi. Amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco laboris nisi.
          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim vis nostrud exercitation ullamco laboris nisi. Amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna.
          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore Amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi. Amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco laboris nisi.
          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim vis nostrud exercitation ullamco laboris nisi. Amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna.
          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore Amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi. Amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco laboris nisi.
          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim vis nostrud exercitation ullamco laboris nisi. Amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna.
          </p>
          <p>
            Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore Amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          </p>
        </div>

        <section class="row no-gutters tyc justify-content-center">
          <div *ngIf="view === legalRepresentativeTermsView">
            <p>
              Declaro tener residencia legal en:
            </p>
            <mat-form-field appearance="outline" class="col-12" [hideRequiredMarker]="true">
              <mat-label>Seleccionar</mat-label>
              <mat-select formControlName="residencia" name="residencia" required>
                <mat-option [value]="1">
                  Colombia
                </mat-option>
                <mat-option [value]="2">
                  USA
                </mat-option>
                <mat-option [value]="3">
                        En otros Países
                </mat-option>
              </mat-select>
              <mat-error *ngIf="checkError('residencia', 'required')">
                Este campo es <strong>requerido</strong>
              </mat-error>
            </mat-form-field>

          </div>
          <div class="col-10">
            <mat-checkbox required class="example-margin" formControlName="Terminos_Y_Condiciones">
              Acepto los Términos y condiciones
            </mat-checkbox>
          </div>
        </section>

        <div class="row justify-content-center-form">
          <div class="col-6">

            <button class="btnSecondary-outlineMobile" type="button"  (click)="moveToPrev()">
              <span  class="material-icons">
                arrow_back_ios
              </span>
              Anterior
            </button>
          </div>

          <div class="col-6">
            <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>

            <button class="btnSecondaryMobile"  [disabled]="!termsAndConditions.valid" type="submit">
              <span class="textBTN">
                siguiente
              </span>
              <!-- Siguiente -->
              <span class="material-icons">
                arrow_forward_ios
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  <!-- </div> -->
</div>
