import { Injectable } from '@angular/core';
import { Method } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { forEach as _forEach } from 'lodash';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LoginCognitoService } from '../login/login-cognito.service';
import { Observable, throwError, from } from 'rxjs';
import { JWTServiceService } from '@services/JWT/jwt-service.service';
import { ActivatedRoute } from '@angular/router';
import { promise } from 'protractor';

const { apiUrl, apiKey } = environment;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private sessionUuid: string;
  private id: string;
  private tokenCognito;

  headers = new HttpHeaders();
  constructor(private login: LoginCognitoService,
    private router: ActivatedRoute,
    private http: HttpClient,
    private token: JWTServiceService
  ) {
    this.sessionUuid = uuidv4();
    this.codeName = 'test-service';
  }

  public makeRequest(method: Method, path: string, payload: any, withFiles?: boolean)
    : Observable<any> {
    const { params, data } = payload;
    if (this.id == null) {
      this.id = '3744868000000659001'
    }
    let httpHeaders = new HttpHeaders()
      .set('X-API-Key', apiKey)
      .append('Process-Id', this.id)
      .append('Session-Tracking', this.sessionUuid)
      .append('Request-Tracking', uuidv4())
      .append('Content-Type', withFiles ? 'multipart/form-data' : 'application/json')

    let options = { headers: httpHeaders };
    if (method == "GET") {
      let resp = this.http.get(`${apiUrl}/${path}`, options)
        .pipe(
          catchError(this.handleError));
      return resp;
    }
    else if (method == "POST") {
      let resp = this.http.post(`${apiUrl}/${path}`, data, options)
        .pipe(
          catchError(this.handleError));
      return resp;
    } else if (method == "PUT") {
      let resp = this.http.post(`${apiUrl}/${path}`, data, options)
        .pipe(
          catchError(this.handleError));
      return resp;
    } else {
      console.log("Error: Falta Method");
    }

  }

  public get(path: string, params?: any) {
    console.log('GET request initiated');
    console.log(`Path: ${path}`);
    console.log('Params:', params);
    return this.makeRequest('GET', path, { params });
    // return this.mockApiRequest();
  }

  public post(path: string, data: any, withFiles?: boolean,): Observable<any> {
    console.log('POST request initiated');
    console.log(`Path: ${apiUrl}/${path}`);
    console.log('Payload:', data);

    return this.makeRequest('POST', path, { data }, withFiles);
    // return this.mockApiRequest();
  }

  public put(path: string, data: any, withFiles?: boolean): Observable<any> {
    console.log('PUT request initiated');
    console.log(`Path: ${path}`);
    console.log('Payload:', data);

    return this.makeRequest('PUT', path, { data }, withFiles);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.log(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

  codeName: string;
  _consoleLog = function (s: string) {
    console.log(s);
  }

  public createIncident(incident: string) {
    const urlPath: string = 'https://public-auth.poc.financierairis.com/oauth2/token';
    this._consoleLog(`${this.codeName}.createIncident: ${incident}`);
    return this.http.post<string>(urlPath, incident)
      .pipe(catchError(this.handleError));
  }
}