<div *desktopOnly>
  <app-header></app-header>
  <app-box class="box"></app-box>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col col-md-8 col-8 m-auto">
        <app-titles [view]="selectedView.view"></app-titles>
        <!-- Form views -->
        <ng-container [ngSwitch]="selectedView.view">
          <app-stepper [amountOfSteps]="amountOfSteps"></app-stepper>
          <app-terms *ngSwitchCase="viewTypes.TERMS_AND_CONDITIONS"></app-terms>
          <app-applicant-form *ngSwitchCase="viewTypes.APPLICANT_DATA"></app-applicant-form>
          <app-company-form *ngSwitchCase="viewTypes.COMPANY_DATA"></app-company-form>
          <app-legal-representative-form *ngSwitchCase="viewTypes.LEGAL_REPRESENTATIVE_DATA">
          </app-legal-representative-form>
          <app-legal-representative-identity-confirmation
            *ngSwitchCase="viewTypes.LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION">
          </app-legal-representative-identity-confirmation>
          <app-data-verification *ngSwitchCase="viewTypes.DATA_VERIFICATION"></app-data-verification>
          <app-legal-representative-terms *ngSwitchCase="viewTypes.LEGAL_REPRESENTATIVE_TERMS_AND_CONDITIONS">
          </app-legal-representative-terms>
          <app-regulations *ngSwitchCase="viewTypes.REGULATIONS"></app-regulations>
          <app-notifications *ngSwitchCase="viewTypes.APPLICATION_COMPLETED" [copy]="applicationCompletedCopy">
          </app-notifications>
          <app-notifications *ngSwitchCase="viewTypes.VERIFICATION_COMPLETED" [copy]="verificationCompletedCopy">
          </app-notifications>
        </ng-container>
      </div>
    </div>
  </div>
</div>


<div *mobileOnly class="mobileOnly">
  <app-header></app-header>
  <app-box class="box"></app-box>
  <!-- <div class="row justify-content-center">
    <div class="col col-sm-8 col-11 m-auto"> -->
  <app-titles [view]="selectedView.view"></app-titles>
  <ng-container [ngSwitch]="selectedView.view">
    <app-stepper [amountOfSteps]="amountOfSteps"></app-stepper>
    <app-terms *ngSwitchCase="viewTypes.TERMS_AND_CONDITIONS"></app-terms>
    <app-applicant-form *ngSwitchCase="viewTypes.APPLICANT_DATA"></app-applicant-form>
    <app-company-form *ngSwitchCase="viewTypes.COMPANY_DATA"></app-company-form>
    <app-legal-representative-form *ngSwitchCase="viewTypes.LEGAL_REPRESENTATIVE_DATA">
    </app-legal-representative-form>
    <app-legal-representative-identity-confirmation
      *ngSwitchCase="viewTypes.LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION">
    </app-legal-representative-identity-confirmation>
    <app-data-verification *ngSwitchCase="viewTypes.DATA_VERIFICATION"></app-data-verification>
    <app-legal-representative-terms *ngSwitchCase="viewTypes.LEGAL_REPRESENTATIVE_TERMS_AND_CONDITIONS">
    </app-legal-representative-terms>
    <app-regulations *ngSwitchCase="viewTypes.REGULATIONS"></app-regulations>
    <app-notifications *ngSwitchCase="viewTypes.APPLICATION_COMPLETED" [copy]="applicationCompletedCopy">
    </app-notifications>
    <app-notifications *ngSwitchCase="viewTypes.VERIFICATION_COMPLETED" [copy]="verificationCompletedCopy">
    </app-notifications>
  </ng-container>
  <!-- </div>
  </div> -->
</div>