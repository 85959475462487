<div *desktopOnly>
  <form [formGroup]="personalInformationFormGroup" (ngSubmit)="onSubmit()">
    <div class="row justify-content-center-form"  *ngIf="view === legalRepresentativeDataView">
      <div class="boxCheckbox">
        <mat-checkbox class="example-margin row col-10 justify-content-center"
          formControlName="isLegalRepresentative" (change)="isLegalRepresentative()">
          Soy el representante legal de la empresa
        </mat-checkbox>
      </div>
    </div>
    <h3 class="col-12" *ngIf="view === applicantDataView">Empresa </h3>
    <div class="row justify-content-center-form" *ngIf="view === applicantDataView">
      <div class="col-md-5">
        <mat-label id="label-text">Razón social</mat-label>
        <mat-form-field appearance="outline" class="text" [hideRequiredMarker]="true"
          style="margin-left: 8%;width: 100%;">
          <input matInput [attr.disabled]="checked" type="text" name="Company" formControlName="Company">
          <mat-error *ngIf="checkError('Company', 'required')">
            Este campo es requerido
          </mat-error>
          <mat-error *ngIf="checkError('Company', 'maxlength')">
            Este campo no puede tener más de 80 caracteres
          </mat-error>
          <mat-error *ngIf="checkError('Company', 'pattern')">
            El nombre que ingresaste no es válido
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-5">
        <mat-label id="label-text">Nit</mat-label>
        <mat-form-field appearance="outline" class="text" [hideRequiredMarker]="true"
          style="margin-left: 8%;width: 100%;">
          <input matInput [attr.disabled]="checked" type="tel" name="NIT" formControlName="NIT"
            (keydown)="numbersOnly($event)">
          <mat-error *ngIf="checkError('NIT', 'required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
          <mat-error *ngIf="checkError('NIT', 'disallowZeroPattern')">
            Este campo no puede empezar con 0
          </mat-error>
          <mat-error *ngIf="checkError('NIT', 'maxlength')">
            Este campo no puede tener más de 9 caracteres
          </mat-error>
          <mat-error *ngIf="checkError('NIT', 'minlength')">
            Este campo no puede tener menos de 9 caracteres
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <h3 class="col-12" *ngIf="view === applicantDataView">Datos del solicitante </h3>
    <div class="row justify-content-center-form" *ngIf="view === applicantDataView">
      <div class="col-md-5">
        <mat-label id="label-text">Nombres</mat-label>
        <mat-form-field appearance="outline" class="text" [hideRequiredMarker]="true"
          style="margin-left: 8%;width: 100%;">
          <input matInput [attr.disabled]="checked" type="text" name="First_Name" formControlName="First_Name" required>
          <mat-error *ngIf="checkError('First_Name', 'required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
          <mat-error *ngIf="checkError('First_Name', 'maxlength')">
            Este campo no puede tener más de 40 caracteres
          </mat-error>
          <mat-error
            *ngIf="checkError('First_Name', 'trimWhitespaces') || checkError('First_Name', 'notAllowedCharsInNames')">
            El nombre que ingresaste no es válido
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-5">
        <mat-label id="label-text">Primer Apellido</mat-label>
        <mat-form-field appearance="outline" style="margin-left: 8%;width: 100%;" [hideRequiredMarker]="true">
          <input matInput [attr.disabled]="checked" type="text" name="Last_Name" formControlName="Last_Name">
          <mat-error *ngIf="checkError('Last_Name', 'required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
          <mat-error *ngIf="checkError('Last_Name', 'maxlength')">
            Este campo no puede tener más de 40 caracteres
          </mat-error>
          <mat-error
            *ngIf="checkError('Last_Name', 'trimWhitespaces') || checkError('Last_Name', 'notAllowedCharsInNames')">
            El nombre que ingresaste no es válido
          </mat-error>
        </mat-form-field>
      </div>
    </div>


    <div class="row justify-content-center-form"  *ngIf="view === applicantDataView">

      <div class="col-md-5">
        <mat-label id="label-text">Segundo Apellido</mat-label>
        <mat-form-field appearance="outline" style="margin-left: 8%;width: 100%;" [hideRequiredMarker]="true">
          <input matInput [attr.disabled]="checked" type="text" name="Segundo_Apellido" formControlName="Segundo_Apellido">
          <mat-error *ngIf="checkError('Last_Name', 'required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
          <mat-error *ngIf="checkError('Last_Name', 'maxlength')">
            Este campo no puede tener más de 40 caracteres
          </mat-error>
          <mat-error
            *ngIf="checkError('Last_Name', 'trimWhitespaces') || checkError('Last_Name', 'notAllowedCharsInNames')">
            El nombre que ingresaste no es válido
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-5">
        <mat-label id="label-text">Tipo de Documento</mat-label>
        <mat-form-field appearance="outline" [hideRequiredMarker]="true" style="margin-left: 8%;width: 100%;">
          <mat-select [disabled]="checked" formControlName="Tipo_De_Identificacion" name="tipoDeIdentificacion" required
            (selectionChange)="documentChange($event)">
            <mat-option [value]="1">
              Cédula de ciudadanía
            </mat-option>
            <mat-option [value]="3">
              Cédula de extranjería
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkError('Tipo_De_Identificacion', 'required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row justify-content-center-form"  *ngIf="view === applicantDataView">
      <div class="col-md-5">
        <mat-label id="label-text">Número de documento</mat-label>
        <mat-form-field appearance="outline" class="telephone" style="margin-left: 8%;width: 100%;">
          <input matInput [attr.disabled]="checked" type="tel" name="Numero_De_Identificacion"
            formControlName="Numero_De_Identificacion" (keydown)="numbersOnly($event)">
          <mat-error *ngIf="checkError('Numero_De_Identificacion', 'required')">
            Este campo es requerido
          </mat-error>
          <mat-error
            *ngIf="checkError('Numero_De_Identificacion', 'disallowZeroPattern') || checkError('Numero_De_Identificacion', 'documentPattern')">
            El número que ingresaste no es válido
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-5" *ngIf="view === applicantDataView">
        <mat-label id="label-text">Fecha de expedición del documento</mat-label>
        <mat-form-field appearance="outline" class="telephone" style="margin-left: 8%;width: 100%;">
          <input matInput [max]="maxDate" [matDatepicker]="picker" formControlName="Fecha_De_Expedicion" required>
          <mat-datepicker-toggle matPrefix [for]="picker" style="width: 5%; height: 20px;"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="checkError('Fecha_De_Expedicion', 'required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
          <mat-error *ngIf="personalInformationFormGroup.controls.Fecha_De_Expedicion.status == 'INVALID'">Fecha
            inválida</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row justify-content-center-form"  *ngIf="view === applicantDataView">
      <div class="col-md-5">
        <mat-label id="label-text">Celular</mat-label>
        <mat-form-field appearance="outline" class="telephone" style="margin-left: 8%;width: 100%;">
          <span matPrefix>
            (+57) 3 &nbsp;
          </span>
          <input matInput [attr.disabled]="checked" type="tel" name="Celular" formControlName="Celular"
            placeholder="5555-12345" (keydown)="numbersOnly($event)">
          <mat-error *ngIf="checkError('Celular', 'required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
          <mat-error *ngIf="checkError('Celular', 'maxlength')">
            Este campo no puede tener más de 9 caracteres
          </mat-error>
          <mat-error *ngIf="checkError('Celular', 'minlength')">
            Este campo requiere 9 números
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-5">
        <mat-label id="label-text">Correo Electronico</mat-label>
        <mat-form-field appearance="outline" class="" style="margin-left: 8%;width: 100%;">
          <input matInput [attr.disabled]="checked" formControlName="Email" type="email"
            placeholder="Ex. pat@example.com" (keydown)="blockSpaces($event)">
          <mat-error *ngIf="checkError('Email', 'required')">
            El Email es requerido
          </mat-error>
          <mat-error *ngIf="checkError('Email', 'email') || checkError('Email', 'trimWhitespaces')">
            Por favor, ingrese un e-mail válido
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row justify-content-center-form" *ngIf="view === legalRepresentativeDataView">
      <div class="col-md-10">
        <mat-label id="label-text" style="margin-left: 4%;width: 100%;">Nombres</mat-label>
        <mat-form-field appearance="outline" class="text" [hideRequiredMarker]="true"
          style="margin-left: 4%;width: 100%;">
          <input matInput [attr.disabled]="checked" type="text" name="First_Name" formControlName="First_Name">
          <mat-error *ngIf="checkError('First_Name', 'required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
          <mat-error *ngIf="checkError('First_Name', 'maxlength')">
            Este campo no puede tener más de 40 caracteres
          </mat-error>
          <mat-error *ngIf="checkError('First_Name', 'trimWhitespaces') || checkError('First_Name', 'notAllowedCharsInNames')">
            El nombre que ingresaste no es válido
          </mat-error>
        </mat-form-field>
      </div>

    </div>

    <div class="row justify-content-center-form" *ngIf="view === legalRepresentativeDataView">


      <div class="col-md-5">
        <mat-label id="label-text">Primer Apellido</mat-label>
        <mat-form-field appearance="outline" style="margin-left: 8%;width: 100%;" [hideRequiredMarker]="true">
          <input matInput [attr.disabled]="checked" type="text" name="Last_Name" formControlName="Last_Name">
          <mat-error *ngIf="checkError('Last_Name', 'required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
          <mat-error *ngIf="checkError('Last_Name', 'maxlength')">
            Este campo no puede tener más de 40 caracteres
          </mat-error>
          <mat-error
            *ngIf="checkError('Last_Name', 'trimWhitespaces') || checkError('Last_Name', 'notAllowedCharsInNames')">
            El nombre que ingresaste no es válido
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-5">
        <mat-label id="label-text">Segundo Apellido</mat-label>
        <mat-form-field appearance="outline" style="margin-left: 8%;width: 100%;" [hideRequiredMarker]="true">
          <input matInput [attr.disabled]="checked" type="text" name="Segundo_Apellido" formControlName="Segundo_Apellido">
          <mat-error *ngIf="checkError('Segundo_Apellido', 'maxlength')">
            Este campo no puede tener más de 40 caracteres
          </mat-error>
          <mat-error *ngIf="checkError('Segundo_Apellido', 'trimWhitespaces') || checkError('Segundo_Apellido', 'notAllowedCharsInNames')">
            El segundo apellido que ingresaste no es válido
          </mat-error>
        </mat-form-field>
      </div>
    </div>


    <div class="row justify-content-center-form"  *ngIf="view === legalRepresentativeDataView">


      <div class="col-md-5">
        <mat-label id="label-text">Tipo de documento</mat-label>
        <mat-form-field appearance="outline" [hideRequiredMarker]="true" style="margin-left: 8%;width: 100%;">
          <mat-select [disabled]="checked" formControlName="Tipo_De_Identificacion" name="tipoDeIdentificacion" required
            (selectionChange)="documentChange($event)">
            <mat-option [value]="1">
              Cédula de ciudadanía
            </mat-option>
            <mat-option [value]="3">
              Cédula de extranjería
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkError('Tipo_De_Identificacion', 'required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-5">
        <mat-label id="label-text">Número de documento</mat-label>
        <mat-form-field appearance="outline" class="telephone" style="margin-left: 8%;width: 100%;">
          <input matInput [attr.disabled]="checked" type="tel" name="Numero_De_Identificacion"
            formControlName="Numero_De_Identificacion" (keydown)="numbersOnly($event)">
          <mat-error *ngIf="checkError('Numero_De_Identificacion', 'required')">
            Este campo es requerido
          </mat-error>
          <mat-error
            *ngIf="checkError('Numero_De_Identificacion', 'disallowZeroPattern') || checkError('Numero_De_Identificacion', 'documentPattern')">
            El número que ingresaste no es válido
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row justify-content-center-form"  *ngIf="view === legalRepresentativeDataView">
      <div class="col-md-5">
        <mat-label id="label-text">Celular</mat-label>
        <mat-form-field appearance="outline" class="telephone" style="margin-left: 8%;width: 100%;">
          <span matPrefix>
            (+57) 3 &nbsp;
          </span>
          <input matInput [attr.disabled]="checked" type="tel" name="Celular" formControlName="Celular"
            placeholder="5555-12345" (keydown)="numbersOnly($event)">
          <mat-error *ngIf="checkError('Celular', 'required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
          <mat-error *ngIf="checkError('Celular', 'maxlength')">
            Este campo no puede tener más de 9 caracteres
          </mat-error>
          <mat-error *ngIf="checkError('Celular', 'minlength')">
            Este campo requiere 9 números
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-5">
        <mat-label id="label-text">Correo Electronico</mat-label>
        <mat-form-field appearance="outline" class="" style="margin-left: 8%;width: 100%;">
          <input matInput [attr.disabled]="checked" formControlName="Email" type="email"
            placeholder="Ex. pat@example.com" (keydown)="blockSpaces($event)">
          <mat-error *ngIf="checkError('Email', 'required')">
            El Email es requerido
          </mat-error>
          <mat-error *ngIf="checkError('Email', 'email') || checkError('Email', 'trimWhitespaces')">
            Por favor, ingrese un e-mail válido
          </mat-error>
        </mat-form-field>
      </div>
    </div>


    <div class="row justify-content-center-form">
      <div class="col-6">

        <button class="btnSecondary-outline" (click)="moveToPrev()" type="button">
          <span class="material-icons">
            arrow_back_ios
          </span>
            Anterior
        </button>
      </div>

      <div class="col-6">
        <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
        <button class="btnSecondary" type="submit" [disabled]="!personalInformationFormGroup.valid" >
          Siguiente
          <span id="right" class="material-icons">
            arrow_forward_ios
          </span>
        </button>
      </div>
    </div>
  </form>
</div>


<!-- ------------------------------------mobile--------------------------------------------------- -->


<div *mobileOnly class="landing-mobile">
  <form [formGroup]="personalInformationFormGroup" (ngSubmit)="onSubmit()">
    <div class="col-12" *ngIf="view === legalRepresentativeDataView">
    </div>
    <h3 class="col-11" *ngIf="view === applicantDataView">Empresa </h3>

    <div class="col-sm-12">
      <mat-label id="label-text">Razón social</mat-label>
      <mat-form-field appearance="outline" class="text" [hideRequiredMarker]="true" style="margin-left: 5%;width: 90%;">
        <input matInput [attr.disabled]="checked" type="text" name="Company" formControlName="Company">
        <mat-error *ngIf="checkError('Company', 'required')">
          Este campo es requerido
        </mat-error>
        <mat-error *ngIf="checkError('Company', 'maxlength')">
          Este campo no puede tener más de 50 caracteres
        </mat-error>
        <mat-error *ngIf="checkError('Company', 'pattern')">
          El nombre que ingresaste no es válido
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-12 col">
      <mat-label id="label-text">Nit</mat-label>
      <mat-form-field appearance="outline" class="text" [hideRequiredMarker]="true" style="margin-left: 5%;width: 90%;">
        <input matInput [attr.disabled]="checked" type="tel" name="NIT" formControlName="NIT"
          (keydown)="numbersOnly($event)">
        <mat-error *ngIf="checkError('NIT', 'required')">
          Este campo es <strong>requerido</strong>
        </mat-error>
        <mat-error *ngIf="checkError('NIT', 'disallowZeroPattern')">
          Este campo no puede empezar con 0
        </mat-error>
        <mat-error *ngIf="checkError('NIT', 'maxlength')">
          Este campo no puede tener más de 9 caracteres
        </mat-error>
        <mat-error *ngIf="checkError('NIT', 'minlength')">
          Este campo no puede tener menos de 9 caracteres
        </mat-error>
      </mat-form-field>
    </div>


    <h3 class="col-11" *ngIf="view === applicantDataView">Datos del solicitante </h3>

    <div class="col-sm-12">
      <mat-label id="label-text">Nombres</mat-label>
      <mat-form-field appearance="outline" class="text" [hideRequiredMarker]="true" style="margin-left: 5%;width: 90%;">
        <input matInput [attr.disabled]="checked" type="text" name="First_Name" formControlName="First_Name" required>
        <mat-error *ngIf="checkError('First_Name', 'required')">
          Este campo es <strong>requerido</strong>
        </mat-error>
        <mat-error *ngIf="checkError('First_Name', 'maxlength')">
          Este campo no puede tener más de 40 caracteres
        </mat-error>
        <mat-error
          *ngIf="checkError('First_Name', 'trimWhitespaces') || checkError('First_Name', 'notAllowedCharsInNames')">
          El nombre que ingresaste no es válido
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-sm-12">
      <mat-label id="label-text">Primer Apellido</mat-label>
      <mat-form-field appearance="outline" style="margin-left: 5%;width: 90%;" [hideRequiredMarker]="true">
        <input matInput [attr.disabled]="checked" type="text" name="Last_Name" formControlName="Last_Name">
        <mat-error *ngIf="checkError('Last_Name', 'required')">
          Este campo es <strong>requerido</strong>
        </mat-error>
        <mat-error *ngIf="checkError('Last_Name', 'maxlength')">
          Este campo no puede tener más de 40 caracteres
        </mat-error>
        <mat-error
          *ngIf="checkError('Last_Name', 'trimWhitespaces') || checkError('Last_Name', 'notAllowedCharsInNames')">
          El nombre que ingresaste no es válido
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-sm-12">
      <mat-label id="label-text">Segundo Apellido</mat-label>
      <mat-form-field appearance="outline" style="margin-left: 5%;width: 90%;" [hideRequiredMarker]="true">
        <input matInput [attr.disabled]="checked" type="text" name="Last_Name" formControlName="Last_Name">
        <mat-error *ngIf="checkError('Last_Name', 'required')">
          Este campo es <strong>requerido</strong>
        </mat-error>
        <mat-error *ngIf="checkError('Last_Name', 'maxlength')">
          Este campo no puede tener más de 40 caracteres
        </mat-error>
        <mat-error
          *ngIf="checkError('Last_Name', 'trimWhitespaces') || checkError('Last_Name', 'notAllowedCharsInNames')">
          El nombre que ingresaste no es válido
        </mat-error>
      </mat-form-field>
    </div>


    <div class="col-sm-12">
      <mat-label id="label-text">Tipo de documento</mat-label>
      <mat-form-field appearance="outline" [hideRequiredMarker]="true" style="margin-left: 5%;width: 90%;">
        <mat-select [disabled]="checked" formControlName="Tipo_De_Identificacion" name="tipoDeIdentificacion" required
          (selectionChange)="documentChange($event)">
          <mat-option [value]="1">
            Cédula de ciudadanía
          </mat-option>
          <mat-option [value]="3">
            Cédula de extranjería
          </mat-option>
        </mat-select>
        <mat-error *ngIf="checkError('Tipo_De_Identificacion', 'required')">
          Este campo es <strong>requerido</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-sm-12">
      <mat-label id="label-text">Número de documento</mat-label>
      <mat-form-field appearance="outline" class="telephone" style="margin-left: 5%;width: 90%;">
        <input matInput [attr.disabled]="checked" type="tel" name="Numero_De_Identificacion"
          formControlName="Numero_De_Identificacion" (keydown)="numbersOnly($event)">
        <mat-error *ngIf="checkError('Numero_De_Identificacion', 'required')">
          Este campo es requerido
        </mat-error>
        <mat-error
          *ngIf="checkError('Numero_De_Identificacion', 'disallowZeroPattern') || checkError('Numero_De_Identificacion', 'documentPattern')">
          El número que ingresaste no es válido
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-sm-12">
      <mat-label id="label-text">Fecha de expedición del Documento</mat-label>
      <mat-form-field appearance="outline" class="telephone" style="margin-left: 5%;width: 90%;">
        <input matInput [max]="maxDate" [matDatepicker]="picker" formControlName="Fecha_De_Expedicion" required>
        <mat-datepicker-toggle matPrefix [for]="picker" style="width: 5%; height: 20px;"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="checkError('Fecha_De_Expedicion', 'required')">
          Este campo es <strong>requerido</strong>
        </mat-error>
        <mat-error *ngIf="personalInformationFormGroup.controls.Fecha_De_Expedicion.status == 'INVALID'">Fecha
          inválida</mat-error>
      </mat-form-field>
    </div>

    <div class="col-sm-12">
      <mat-label id="label-text">Celular</mat-label>
        <mat-form-field appearance="outline" class="telephone" style="margin-left: 5%;width: 90%;">
          <span matPrefix>
            (+57) 3 &nbsp;
          </span>
          <input matInput [attr.disabled]="checked" type="tel" name="Celular" formControlName="Celular"
            placeholder="5555-12345" (keydown)="numbersOnly($event)">
          <mat-error *ngIf="checkError('Celular', 'required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
          <mat-error *ngIf="checkError('Celular', 'maxlength')">
            Este campo no puede tener más de 9 caracteres
          </mat-error>
          <mat-error *ngIf="checkError('Celular', 'minlength')">
            Este campo requiere 9 números
          </mat-error>
        </mat-form-field>
    </div>

    <div class="col-sm-12">
      <mat-label id="label-text">Correo Electrónico</mat-label>
        <mat-form-field appearance="outline" class="telephone" style="margin-left: 5%;width: 90%;">
          <input matInput [attr.disabled]="checked" formControlName="Email" type="email"
            placeholder="Ex. pat@example.com" (keydown)="blockSpaces($event)">
          <mat-error *ngIf="checkError('Email', 'required')">
            El e-mail es requerido
          </mat-error>
          <mat-error *ngIf="checkError('Email', 'email') || checkError('Email', 'trimWhitespaces')">
            Por favor, ingrese un e-mail válido
          </mat-error>
        </mat-form-field>
    </div>

    <div class="col-md-12">
      <button class="btnSecondary-outlineMobile" (click)="moveToPrev()">
        <span class="material-icons">
          arrow_back_ios
        </span>
        Anterior
      </button>

      <button class="btnSecondaryMobile" [disabled]="!personalInformationFormGroup.valid" >
        Siguiente
        <span class="material-icons">
          arrow_forward_ios
        </span>
      </button>
    </div>
