import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginCognitoService } from '../services/login/login-cognito.service';
import { Router } from '@angular/router';
import { JWTServiceService } from '@services/JWT/jwt-service.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: LoginCognitoService,
        private router: Router,
        private jwtService: JWTServiceService) { }

    async handle(req: HttpRequest<any>, next: HttpHandler) {
        // if your getAuthToken() function declared as "async getAuthToken() {}"
        let tokenCognito = this.auth.tokenCognito;
        if (!tokenCognito) {
            await this.auth.createToken().then((respose: any) => {
                if (respose && respose.access_token) {
                    this.auth.tokenCognito = respose.access_token;
                }
            })
            .catch((error) => {
                console.log('HTTP ERROR:');
                console.log(error);
                return null;
            });
        }
        else {
            var decoded = this.jwtService.getDecodedAccessToken(tokenCognito);
            const expiry = decoded.exp;
            if ((Math.floor((new Date).getTime() / 1000)) >= expiry) {
                await this.auth.createToken().then((respose: any) => {
                    if (respose && respose.access_token) {
                        this.auth.tokenCognito = respose.access_token;
                    }
                })
                .catch((error) => {
                    console.log('HTTP ERROR:');
                    console.log(error);
                    return null;
                });
            }
        }

        const authReq = req.clone({
            setHeaders: {
                Authorization: 'Bearer ' + this.auth.tokenCognito
            }
        })

        // Important: Note the .toPromise()
        return next.handle(authReq).toPromise()
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handle(request, next))
    }
}