import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../loading/loading.service'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor( private loading : LoadingService) { 

    this.loading.spin$.next(true);
  }

  ngOnInit(): void {

    setTimeout(
      () => this.loading.spin$.next(false), 2000
     )
 
     setTimeout(
      () => this.loading.spin$.next(false), 3000
     )
 
     setTimeout(
      () => this.loading.spin$.next(true), 4000
     )
     setTimeout(
      () => this.loading.spin$.next(false), 5000
     )
  }

}



