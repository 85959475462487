import { Component, OnInit, Input } from '@angular/core';

import { View } from '../../../enums';

@Component({
  selector: 'app-titles',
  templateUrl: './titles.component.html',
  styleUrls: ['./titles.component.scss']
})
export class TitlesComponent implements OnInit {
  @Input() view: View;

  public viewTypes = View;

  constructor () { }

  ngOnInit(): void {
  }

}
