import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  @Output() public typeOfProcess = new EventEmitter();
  router: Router;
  payload = {};

  constructor (router: Router) {
    this.router = router;
  }

  ngOnInit(): void {
  }

  startProcess ( type ) {
    this.typeOfProcess.emit(type);
  }

  navigate(type): void {
    this.payload = type;
    this.router.navigate(['/landing/documentation'], {state: this.payload});
   }

}
