import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationService, FormStateService, ViewManagerService } from '../../../../services';
import { DocumentUploadService } from '../../../../services/documents-upload/documents-upload.service';
import { View } from '../../../../enums';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyFormComponent implements OnInit {

  public businessInformationFormGroup: FormGroup;
  disallowZeroPattern = /^(?:[1-9]\d*|0)$/;

  loading = false;
  fileNameCamara: string;
  fileNameAccionaria: string;

  constructor(
    private applicationService: ApplicationService,
    private formState: FormStateService,
    private viewManager: ViewManagerService,
    private changeDetectorRef: ChangeDetectorRef,
    private DocumentUpload: DocumentUploadService,
  ) { }

  ngOnInit(): void {
    this.businessInformationFormGroup = this.formState.companyDataFormState || new FormGroup({
      uploadFileCamara: new FormControl(
        '', [Validators.required]
      ),
      uploadFileAccionaria: new FormControl(''),
    });

    this.formState.companyDataFiles = {
      chamberOfCommerceCertificate: null,
      shareholderStructure:null,
    };
  }

  checkError(controlName: string, errorName: string) {
    return this.businessInformationFormGroup.controls[controlName].hasError(
      errorName,
    );
  }

  onSubmit() {
    this.loading = true;
    this.formState.companyDataFormState = this.businessInformationFormGroup;
    this.DocumentUpload.DocumentUpload().subscribe(() => {
    this.viewManager.setView(({ view: View.LEGAL_REPRESENTATIVE_DATA }));
    this.loading = false;
    });
  }

  moveToPrev() {
    this.viewManager.setView(({ view: View.APPLICANT_DATA }));
  }

  fileCamara(event: any) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.type !== 'application/pdf' && event.target.files[0].size <= 5242880) {
        this.businessInformationFormGroup.controls.uploadFileCamara.setErrors({
          invalid: true,
        });
      } else {
        this.fileNameCamara = event.target.files[0].name;
        this.formState.companyDataFiles.chamberOfCommerceCertificate = event.target.files[0];
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  fileAccionaria(event: any) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.type !== 'application/pdf' && event.target.files[0].size <= 5242880) {
        this.businessInformationFormGroup.controls.uploadFileAccionaria.setErrors({
          invalid: true,
        });
      } else {
        this.fileNameAccionaria = event.target.files[0].name;
        this.formState.companyDataFiles.shareholderStructure = event.target.files[0];
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  clearCamara() {
    this.fileNameCamara = null;
    this.businessInformationFormGroup.value.file = null;
  }

  clearAccionaria() {
    this.fileNameAccionaria = null;
  }

  // todo Google Docs & OneDrive
  upload() {
    console.log('subiendo documento');
  }
}