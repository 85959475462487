<p>regulations works!</p>
<div class="row justify-content-center no-gutters">
  <div class="col-12 col-md-6 order-12 order-md-1">
    <button class="btn-primary-outline" (click)="cancel()" type="button">
      <span class="material-icons">
        arrow_back_ios
      </span>
      Volver
    </button>
  </div>
  <div class="col-12 col-md-6 order-1 order-md-12 d-flex justify-content-end" >
    <button class="btn-primary" (click)="next()">
      Siguiente
      <span class="material-icons">
        arrow_forward_ios
      </span>
    </button>
  </div>
</div>
