import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ViewManagerService } from '../../../../services';
import { JWTServiceService } from '@services/JWT/jwt-service.service';
import { ApiService } from '@services/api/api.service';
import { View } from '../../../../enums';

@Component({
  selector: 'app-resume-process',
  templateUrl: './resume-process.component.html',
  styleUrls: ['./resume-process.component.scss']
})
export class ResumeProcessComponent implements OnInit {

  constructor(private router: ActivatedRoute,
    private Router : Router ,
     private token: JWTServiceService,
     private api: ApiService,
     private viewManager: ViewManagerService,
     ) { }

  ngOnInit(): void {
    this.router.queryParams.subscribe(queryParams => {
      if (queryParams) {
        const token: string = this.router.snapshot.queryParams["token"];
        if (token) {
          let raw = JSON.stringify({ 'applicationId': '09d560c2-f6ab-4c62-b039-dace357e75a9', 'token': token });
          return this.api.post('utilities/v0/security/validate', raw, false).toPromise()
            .then(result => {
              console.log(result)
              let tokenInfo = this.token.getDecodedAccessToken(token);
              let processStageId = tokenInfo.processStageId;              
              if (tokenInfo) {                
                switch (processStageId) {
                  case this.processStage.datosSolicitud: {      
                    this.viewManager.setView({ view: View.APPLICANT_DATA });                                                                               
                    break;
                  }
                  case this.processStage.documentacionSolicitud: {
                    this.viewManager.setView({ view: View.COMPANY_DATA });                                                      
                    break;
                  }
                  case this.processStage.titularProducto: {  
                    this.viewManager.setView({ view: View.LEGAL_REPRESENTATIVE_DATA});                                                             
                    break;
                  }
                  // case this.processStage.procesoValidacion: {
                    
                  //   this.viewManager.setView({ view: View. });                                         

                  //   break;
                  // }
                  // case this.processStage.informacionAdicionalSolicitud: {
                    
                  //   this.viewManager.setView({ view: View. });                                         

                  //   break;
                  // }
                  // case this.processStage.validacionInformacionAdicional: {
                    
                  //   this.viewManager.setView({ view: View. });                                         

                  //   break;
                  // }
                  // case this.processStage.aceptacionSolicitud: {
                    
                  //   this.viewManager.setView({ view: View. });                                         

                  //   break;
                  // }
                  // case this.processStage.rechazoSolicitud: {
                    
                  //   this.viewManager.setView({ view: View. });                                         

                  //   break;
                  // }
                  // case this.processStage.firmaDigitalDocumentos: {
                  //   this.viewManager.setView({ view: View. });                                         
                    

                  //   break;
                  // }
                  // case this.processStage.rechazoSolicitudTitular: {
                  //   this.viewManager.setView({ view: View. });                                         
                    

                  //   break;
                  // }
                  case this.processStage.finalizado: {                    
                    this.viewManager.setView({ view: View.APPLICATION_COMPLETED });
                    break;
                  }
                  default: {
                    this.viewManager.setView({ view: View.APPLICANT_DATA });
                    break;
                  }
                }
                this.Router.navigate(['/onboarding/unipersonal']);  
              }
              console.log(tokenInfo)
              // result.toPromise().then(res =>{
              //   console.log(res);
              // })
            }).catch(error => console.log('error', error));
        } else {
          console.log('Error Token')
        }
      } else {
        console.log('error')
      }
    });
  }

  processStage = {
    datosSolicitud: '100. Datos de solicitud',
    documentacionSolicitud: '200. Documentación de la solicitud',
    titularProducto: '300. Datos de solicitud - Titular del producto',
    procesoValidacion: '400. En proceso de validación(interno)',
    informacionAdicionalSolicitud: '500. Solicitud Información adicional (opcional)',
    validacionInformacionAdicional: '600. Validación de información adicional',
    aceptacionSolicitud: '700. Aceptación de la solicitud (interno)',
    rechazoSolicitud: '750. Rechazo de la solicitud (interno)',
    firmaDigitalDocumentos: '800. Firma digital de documentos',
    rechazoSolicitudTitular: '850. Rechazo de la solicitud (titular)',
    finalizado: '1000. Proceso finalizado'
  }
}