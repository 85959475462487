import { Injectable } from '@angular/core';
import  config  from './config';
import { Observable } from 'rxjs';
import { DocumentsModel } from '../../interfaces';
import { FormStateService } from '@services/form-state/form-state.service';
import { ApiService } from '../api/api.service';

const poolData = config.poolData;

@Injectable({
  providedIn: 'root'
})
export class DocumentUploadService {

  constructor(private api: ApiService, private formState: FormStateService) { }
  
  DocumentUpload(): Observable<any> {
    const documents: DocumentsModel = this.formState.companyDataFiles;
    let DocumentUpload = new Observable();
    try {
      const formData : FormData = new FormData();
      if(documents){
        if(documents.chamberOfCommerceCertificate){
          formData.append('chamberOfCommerceCertificate', documents.chamberOfCommerceCertificate);
        }
        if(documents.shareholderStructure){
          formData.append('shareholderStructure', documents.shareholderStructure);
        }
      }
      
      
      let resp = this.api.post(poolData.url, formData, true);
      return resp;
    }
    catch (e) {
      console.log(e);
    }
    return DocumentUpload;
  }
}
