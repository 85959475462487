import { Component, OnInit } from '@angular/core';

import { View } from '../../../../enums';

@Component({
  selector: 'app-legal-representative-form',
  templateUrl: './legal-representative-form.component.html',
  styleUrls: ['./legal-representative-form.component.scss']
})
export class LegalRepresentativeFormComponent implements OnInit {

  public view = View.LEGAL_REPRESENTATIVE_DATA;

  constructor () { }

  ngOnInit(): void {
  }

}
