export default {
    poolData : {
        url: 'https://public-auth.poc.financierairis.com/oauth2/token',
        ClientId: '4khetvd2464auh9n9n64g05g2f',
        ClientSecret: 'bji1bo6jpmjgl91o0ma9o0u2fekcsbdp18vnffgd643690opipm',
        grant_type: 'client_credentials',
        scope: 'onboarding-digital/public'
    },
    apiEndPoint : "",
    kushkiapiEndPoint : ""
};