import { Component, OnInit, Input, ElementRef, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ApplicationService, FormStateService, ViewManagerService } from '../../../services';
import { View } from '../../../enums';
import { disallowZeroPattern, regexValidator, } from '../../../helpers/custom-form-validators';

@Component({
  selector: 'app-personal-data-form',
  templateUrl: './personal-data-form.component.html',
  styleUrls: ['./personal-data-form.component.scss'],
})
export class PersonalDataFormComponent implements OnInit {
  @Input() view: View;
  

  public applicantDataView = View.APPLICANT_DATA;
  public legalRepresentativeDataView = View.LEGAL_REPRESENTATIVE_DATA;

  public maxDate: any;

  documentPattern1 = /^(?:\d{0,8}|\d{10})$/;
  documentPattern3 = /^\d{0,11}$/;

  private regExpValidators = {
    trimWhitespaces: /^([\S]+[\s]*)*[\S]+$/,
    notAllowedCharsInNames: /^[^\.\,\"\?\º\`\¿\¡\!\;\:\#\$\%\&\(\)\*\+\/\<\>\=\@\[\]\\\^\_\{\}\|\~]+$/i,
  };

  public personalInformationFormGroup: FormGroup;

  public loading = false;
  public checked = false;

  constructor (
    private applicationService: ApplicationService,
    private formState: FormStateService,
    private viewManager: ViewManagerService,
    private elRef: ElementRef
  ) {
    this.maxDate = moment().subtract(1, 'days').format();
  }
  @HostListener('click') onClick() {
    this.elRef.nativeElement.blur();
  }

  ngOnInit(): void {
    if (this.view === View.APPLICANT_DATA) {
      this.personalInformationFormGroup = this.formState.applicantDataFormState || new FormGroup({
        isLegalRepresentative: new FormControl('', []),
        Company: new FormControl('', [
          Validators.required,
          Validators.maxLength(80),
          Validators.minLength(1),
        ]),
        NIT: new FormControl('', [
          Validators.required,
          regexValidator(disallowZeroPattern, 'disallowZeroPattern'),
          Validators.maxLength(9),
        ]),

        First_Name: new FormControl('', [
          Validators.required,
          Validators.maxLength(40),
          regexValidator(
            this.regExpValidators.notAllowedCharsInNames,
            'notAllowedCharsInNames'
          ),
          regexValidator(
            this.regExpValidators.trimWhitespaces,
            'trimWhitespaces'
          ),
        ]),

        Last_Name: new FormControl('', [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(40),
          regexValidator(
            this.regExpValidators.notAllowedCharsInNames,
            'notAllowedCharsInNames'
          ),
          regexValidator(
            this.regExpValidators.trimWhitespaces,
            'trimWhitespaces'
          ),
        ]),

        Segundo_Apellido: new FormControl('', [
          Validators.minLength(1),
          Validators.maxLength(40),
          regexValidator(
            this.regExpValidators.notAllowedCharsInNames,
            'notAllowedCharsInNames'
          ),
          regexValidator(
            this.regExpValidators.trimWhitespaces,
            'trimWhitespaces'
          ),
        ]),

        Tipo_De_Identificacion: new FormControl('', [Validators.required]),

        // Si tipoDeIdentificacion es 1: de 1 a 8 caracteres, no 9, si 10 caracteres, no puede empezar en 0
        // Si tipoDeIdentificacion es 3: minimo 1, max 12 caracteres, no puede empezar en 0

        Numero_De_Identificacion: new FormControl('', [
          Validators.required,
          regexValidator(disallowZeroPattern, 'disallowZeroPattern'),
          regexValidator(this.documentPattern1, 'documentPattern'),
        ]),

        Fecha_De_Expedicion: new FormControl('', [Validators.required]),

        Celular: new FormControl('', [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9),
        ]),

        Email: new FormControl('', [
          Validators.required,
          Validators.nullValidator,
          Validators.email,
          regexValidator(
            this.regExpValidators.trimWhitespaces,
            'trimWhitespaces'
          ),
        ]),

      });
    } else if(this.view === View.LEGAL_REPRESENTATIVE_DATA){
      this.personalInformationFormGroup = new FormGroup({
        isLegalRepresentative: new FormControl('', []),
        First_Name: new FormControl('', [
          Validators.required,
          Validators.maxLength(40),
          regexValidator(
            this.regExpValidators.notAllowedCharsInNames,
            'notAllowedCharsInNames'
          ),
          regexValidator(
            this.regExpValidators.trimWhitespaces,
            'trimWhitespaces'
          ),
        ]),

        Last_Name: new FormControl('', [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(40),
          regexValidator(
            this.regExpValidators.notAllowedCharsInNames,
            'notAllowedCharsInNames'
          ),
          regexValidator(
            this.regExpValidators.trimWhitespaces,
            'trimWhitespaces'
          ),
        ]),

        Segundo_Apellido: new FormControl('', [
          Validators.minLength(1),
          Validators.maxLength(40),
          regexValidator(
            this.regExpValidators.notAllowedCharsInNames,
            'notAllowedCharsInNames'
          ),
          regexValidator(
            this.regExpValidators.trimWhitespaces,
            'trimWhitespaces'
          ),
        ]),

        Tipo_De_Identificacion: new FormControl('', [Validators.required]),

        // Si tipoDeIdentificacion es 1: de 1 a 8 caracteres, no 9, si 10 caracteres, no puede empezar en 0
        // Si tipoDeIdentificacion es 3: minimo 1, max 12 caracteres, no puede empezar en 0

        Numero_De_Identificacion: new FormControl('', [
          Validators.required,
          regexValidator(disallowZeroPattern, 'disallowZeroPattern'),
          regexValidator(this.documentPattern1, 'documentPattern'),
        ]),
        Celular: new FormControl('', [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9),
        ]),
        Email: new FormControl('', [
          Validators.required,
          Validators.nullValidator,
          Validators.email,
          regexValidator(
            this.regExpValidators.trimWhitespaces,
            'trimWhitespaces'
          ),
        ]),
      });
    }
  }
  documentChange(event) {
    if (event.value === 1) {
      this.personalInformationFormGroup.controls.Numero_De_Identificacion.setValidators(
        [
          Validators.required,
          regexValidator(disallowZeroPattern, 'disallowZeroPattern'),
          regexValidator(this.documentPattern1, 'documentPattern'),
        ]
      );
    } else {
      this.personalInformationFormGroup.controls.Numero_De_Identificacion.setValidators(
        [
          Validators.required,
          regexValidator(disallowZeroPattern, 'disallowZeroPattern'),
          regexValidator(this.documentPattern3, 'documentPattern'),
        ]
      );
    }
    this.personalInformationFormGroup.controls.Numero_De_Identificacion.updateValueAndValidity();
  }
  checkError(controlName: string, errorName: string) {
    return this.personalInformationFormGroup.controls[controlName].hasError(
      errorName
    );
  }
  numbersOnly(event: KeyboardEvent): boolean {
    const { key } = event;
    // check if key property is present
    return (
      (key &&
        // allow numbers
        /\d/.test(key)) ||
      // allow backspace, delete, tab, enter and arrows
      [
        'Backspace',
        'Delete',
        'Tab',
        'Enter',
        'ArrowLeft',
        'ArrowRight',
      ].includes(key)
    );
  }
  blockSpaces(event: KeyboardEvent): boolean {
    const { key } = event;

    return key && key !== ' ';
  }
  isLegalRepresentative() {
    if (!this.checked) {
      this.personalInformationFormGroup.patchValue({
        First_Name: this.formState.applicantDataFormState.value.First_Name,
        Last_Name: this.formState.applicantDataFormState.value.Last_Name,
        Segundo_Apellido: this.formState.applicantDataFormState.value.Segundo_Apellido,
        Tipo_De_Identificacion: this.formState.applicantDataFormState.value.Tipo_De_Identificacion,
        Numero_De_Identificacion: this.formState.applicantDataFormState.value.Numero_De_Identificacion,
        Celular: this.formState.applicantDataFormState.value.Celular,
        Email: this.formState.applicantDataFormState.value.Email,
        isLegalRepresentative: true,
      });
      this.checked = true;
    } else {
      this.checked = false;
      this.personalInformationFormGroup.reset();
    }
  }
  onSubmit() {
    this.loading = true;
    const formData = { ...this.personalInformationFormGroup.value };
    if (this.view === View.APPLICANT_DATA) {
      this.formState.applicantDataFormState = this.personalInformationFormGroup;
      this.applicationService.createApplication().toPromise().then(() => {
        this.loading = false;
        this.viewManager.setView({ view: View.COMPANY_DATA });
      });
    } else if(this.checked == false){
      this.formState.legalRepresentativeDataFormState = this.personalInformationFormGroup;
      this.applicationService.registerLegalRepresentative().toPromise().then(() => {
        this.loading = false;
        this.viewManager.setView({ view: View.APPLICATION_COMPLETED });
      });
    }else if(this.checked == true){
      this.formState.legalRepresentativeIdentityConfirmationFormState = this.personalInformationFormGroup;
      this.applicationService.registerLegalRepresentativeConfirmed().toPromise().then(() => {
        this.loading = false;
        // this.viewManager.setView({ view: View.APPLICATION_COMPLETED });
        this.viewManager.setView({ view: View.LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION }); //borrar despues : esta solo para probar la vista
      });
    }
  }
  // moveToPrev() {
  //   if (this.view === View.APPLICANT_DATA) {
  //     this.router.navigate(['landing']);
  //   } else {
  //     this.viewManager.setView({ view: View.COMPANY_DATA });
  //   }
  // }

  moveToPrev() {
    if (this.view === View.APPLICANT_DATA) {
    this.viewManager.setView({ view: View.TERMS_AND_CONDITIONS });
  } else {
      this.viewManager.setView({ view: View.LEGAL_REPRESENTATIVE_IDENTITY_CONFIRMATION });
    }
  }
}
