import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpBackend  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import config from './config';

const poolData = config.poolData;

@Injectable({
  providedIn: 'root'
})
export class LoginCognitoService {
  public tokenCognito;
  private http: HttpClient;
  constructor(handler: HttpBackend) {
    this.codeName = 'test-service';
    this.http = new HttpClient(handler);
  }

  codeName: string;
  _consoleLog = function (s: string) {
    console.log(s);
  }

  public createIncident(incident: string) {
    const urlPath: string = 'https://public-auth.poc.financierairis.com/oauth2/token';
    this._consoleLog(`${this.codeName}.createIncident: ${incident}`);
    return this.http.post<string>(urlPath, incident)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.log('An error occurred:', error.error.message);
    } else {
      console.log(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  async createToken() {
    let body = "client_id=" + poolData.ClientId + "&grant_type=" + poolData.grant_type + "&scope=" + poolData.scope;
    let basic = "Basic " + btoa(poolData.ClientId + ":" + poolData.ClientSecret);
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .append('Authorization', basic);
    let options = { headers: httpHeaders };

    return await this.http.post(poolData.url, body, options).toPromise();
  }

  // getToken(): Observable<any> {
  //   let body = "client_id=" + poolData.ClientId + "&grant_type=" + poolData.grant_type + "&scope=" + poolData.scope;
  //   let basic = "Basic " + btoa(poolData.ClientId + ":" + poolData.ClientSecret);
  //   let httpHeaders = new HttpHeaders()
  //     .set('Content-Type', 'application/x-www-form-urlencoded')
  //     .append('Authorization', basic);
  //   let options = { headers: httpHeaders };
  //   try {
  //     let resp = this.http.post(poolData.url, body, options).pipe(
  //       catchError(this.handleError));
  //     return resp;
  //   }
  //   catch (e) {
  //     console.log(e);
  //   }
  //   return null;
  // }
}